import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateFnsLocale from 'date-fns/locale/en-GB';
import { ApolloClient } from 'apollo-client';
import { fromPromise, ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'emotion-theming';
import ScrollToTop from '@mvpf/platform-shared/components/ScrollToTop';
import {
  getToken,
  removeToken,
  setToken,
  getRefreshToken,
  removeRefreshToken
} from '@mvpf/platform-shared/utils/auth';
import muiTheme from '@mvpf/platform-shared/styles/muiTheme';
import { StylesProvider } from '@material-ui/styles';
import App from './App';
import { toast } from 'react-toastify';
import ErrorBoundary from '@mvpf/platform-shared/components/ErrorBoundary';
import { logError } from '@mvpf/platform-shared/utils/error';
import getAppUrl from '@mvpf/platform-shared/utils/getAppUrl';
import getEnv from '@mvpf/platform-shared/utils/getEnv';
import theme from '@mvpf/platform-shared/constants/theme';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css';
import '@mvpf/platform-shared/styles/base.css';
import 'intersection-observer';

if (process.env.REACT_APP_ADMIN_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_ADMIN_SENTRY_URL,
    environment: getEnv()
  });
}

const httpLink = createUploadLink({
  uri: `${getAppUrl('api')}/graphql`
});

const authLink = setContext(() => {
  const headers = {};

  if (getToken()) {
    headers.authorization = `Bearer ${getToken()}`;
  }

  return {
    headers
  };
});

const getNewToken = () => {
  return new Promise(async resolve => {
    return resolve(
      setToken(
        (await client.mutate({
          mutation: refreshTokenMutation,
          variables: {
            refreshToken: getRefreshToken()
          }
        })).data.refreshToken.token
      )
    );
  });
};

const errorLink = onError(
  ({ response, operation, graphQLErrors, networkError, forward }) => {
    if (
      operation.operationName === 'signinAdmin' ||
      operation.operationName === 'createAdmin' ||
      operation.operationName === 'createUser' ||
      operation.operationName === 'createProject' ||
      operation.operationName === 'addTagToUser' ||
      operation.operationName === 'createSkill' ||
      operation.operationName === 'uploadCv'
    ) {
      // response.errors = null;
      return;
    }

    // TODO: implement refresh token
    // https://www.apollographql.com/docs/link/links/error.html#retry-request
    if (networkError && networkError.statusCode === 401) {
      removeToken();
      window.location.href = '/';
      return;
    }

    if (networkError && networkError.result && networkError.result.errors) {
      for (let { extensions } of graphQLErrors) {
        if (
          extensions &&
          extensions.extensions &&
          extensions.extensions.code === 'TokenExpiredError'
        ) {
          removeToken();
          // Refresh access token
          return fromPromise(getNewToken()).flatMap(() => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${getToken()}`
              }
            });

            // retry the request, returning the new observable
            return forward(operation);
          });
        }
      }
    }

    if (graphQLErrors) {
      let shownCustomError = false;
      for (let { extensions, message, locations, path } of graphQLErrors) {
        if (extensions && extensions.code === 'BAD_USER_INPUT' && message) {
          toast.error(message);
          shownCustomError = true;
        }
        if (
          extensions &&
          extensions.exception &&
          extensions.exception.name === 'TokenExpiredError'
        ) {
          removeToken();
          removeRefreshToken();
          window.location.href = '/';
        }

        logError(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
      if (!shownCustomError) {
        toast.error("Something's gone wrong. Our team has been notified.");
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only'
  },
  query: {
    fetchPolicy: 'network-only'
  }
};

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  defaultOptions
});

const Root = () => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocale}>
        <ApolloProvider client={client}>
          <Router>
            <ScrollToTop>
              <ErrorBoundary>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </ErrorBoundary>
            </ScrollToTop>
          </Router>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

const refreshTokenMutation = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
    }
  }
`;
