import React from 'react';
import { css } from 'glamor';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressButton = ({ loading, children, ...otherProps }) => (
  <Button disabled={loading} {...otherProps}>
    {children}
    {loading && (
      <CircularProgress
        size={24}
        className={`${css({
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12
        })}`}
      />
    )}
  </Button>
);

export default ProgressButton;
