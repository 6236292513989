import React from 'react';
import gql from 'graphql-tag';
import { propType } from 'graphql-anywhere';
import { Div, H4, P, Span } from 'glamorous';
import ReadIcon from '@material-ui/icons/Check';
import UnreadIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import formatDistance from 'date-fns/formatDistance';
import { COLORS } from '@mvpf/platform-shared/constants/theme';

const Notification = ({
  title,
  description,
  notification,
  onMarkUnread,
  onMarkRead
}) => (
  <Div display="flex" padding={10} borderBottom={`1px solid ${COLORS.alto}`}>
    <Div flexShrink={0} marginRight={10}>
      {notification.isRead ? (
        <Tooltip title="Mark as unread">
          <IconButton
            onClick={() => {
              onMarkUnread && onMarkUnread(notification.id);
            }}
          >
            <UnreadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Mark as read">
          <IconButton
            onClick={() => {
              onMarkRead && onMarkRead(notification.id);
            }}
          >
            <ReadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Div>
    <Div flex={1}>
      <H4 fontSize={16} marginTop={0} marginBottom={5}>
        {title}
      </H4>

      <P color={COLORS.cadetBlue} marginTop={0} marginBottom={10}>
        {description}
      </P>

      <Span color={COLORS.cadetBlue} opacity={0.5}>
        {formatDistance(notification.createdAt, new Date(), {
          addSuffix: true
        })}
      </Span>
    </Div>
  </Div>
);

Notification.fragments = {
  notification: gql`
    fragment SidebarNotification on Notification {
      __typename
      id
      isRead
      createdAt
      ... on CheckAssignmentNotification {
        application {
          id
          user {
            id
            fullName
            primaryPosition
          }
        }
      }
      ... on NewApplicationNotification {
        application {
          id
          user {
            id
            fullName
            primaryPosition
          }
        }
      }
      ... on ProjectHealthReportingNotification {
        project {
          id
          name
        }
      }
      ... on ProjectPhaseChangedNotification {
        project {
          id
          name
        }
        activity {
          id
          vars
        }
      }
      ... on ProjectStatusChangedNotification {
        project {
          id
          name
        }
        activity {
          id
          vars
        }
      }
      ... on TechInterviewAcceptedNotification {
        application {
          id
          user {
            id
            fullName
            primaryPosition
          }
          techInterviewer {
            id
            fullName
          }
        }
      }
      ... on TechInterviewCompletedNotification {
        application {
          id
          user {
            id
            fullName
            primaryPosition
          }
          techInterviewer {
            id
            fullName
          }
        }
      }
      ... on AdminApproveTimesheetsNotification {
        project {
          id
          name
        }
      }
      ... on AdminFillTimesheetNotification {
        project {
          id
          name
        }
      }
    }
  `
};

Notification.propTypes = {
  notification: propType(Notification.fragments.notification).isRequired
};

export default Notification;
