import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';

const AdminApproveTimesheetsNotification = ({
  notification,
  ...otherProps
}) => (
  <Notification
    title={
      <Fragment>
        Approve this week's timesheets for{' '}
        <Link to={`/projects/${notification.project.id}/time`}>
          {notification.project.name}
        </Link>
      </Fragment>
    }
    description={`Please take a look at the timesheets of your teammates at ${notification.project.name} and approve them if they are correct.`}
    notification={notification}
    {...otherProps}
  />
);

export default AdminApproveTimesheetsNotification;
