import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ProgressButton from '@mvpf/platform-shared/components/ProgressButton';
import TextFieldAdapter from '@mvpf/platform-shared/components/TextFieldAdapter';
import ErrorMessage from '@mvpf/platform-shared/components/ErrorMessage';
import { Row, Col } from '@mvpf/platform-shared/components/Grid';
import {
  required,
  mustBeEmail,
  composeValidators
} from '@mvpf/platform-shared/utils/validation';

const NewAdminDialog = ({ onClose }) => (
  <Dialog disableBackdropClick open onClose={onClose}>
    <Mutation mutation={createAdminMutation}>
      {createAdmin => (
        <Form
          onSubmit={async values => {
            try {
              await createAdmin({
                variables: {
                  email: values.email,
                  firstName: values.firstName,
                  lastName: values.lastName
                }
              });

              toast.success('Admin created');

              onClose();
            } catch (error) {
              return { [FORM_ERROR]: error.graphQLErrors[0].message };
            }
          }}
          render={({ handleSubmit, submitting, submitError }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>New Admin</DialogTitle>
              <DialogContent>
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                <Row>
                  <Col size={6}>
                    <Field
                      name="firstName"
                      component={TextFieldAdapter}
                      validate={required}
                      label="First Name"
                      margin="normal"
                      fullWidth
                    />
                  </Col>
                  <Col size={6}>
                    <Field
                      name="lastName"
                      component={TextFieldAdapter}
                      validate={required}
                      label="Last Name"
                      margin="normal"
                      fullWidth
                    />
                  </Col>
                </Row>

                <Field
                  name="email"
                  component={TextFieldAdapter}
                  validate={composeValidators(required, mustBeEmail)}
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ProgressButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={submitting}
                >
                  Add
                </ProgressButton>
              </DialogActions>
            </form>
          )}
        />
      )}
    </Mutation>
  </Dialog>
);

const createAdminMutation = gql`
  mutation createAdmin(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    createAdmin(email: $email, firstName: $firstName, lastName: $lastName) {
      id
    }
  }
`;

export default NewAdminDialog;
