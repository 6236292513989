import React from 'react';
import glamorous from 'glamorous';
import TooltipTrigger from 'react-popper-tooltip';
import { COLORS } from '../constants/theme';
import Z_INDEX from '../constants/z-index';

const TooltipContainer = glamorous.div(
  {
    padding: 15,
    margin: 10,
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 4px',
    border: `1px solid ${COLORS.cadetBlue}`,
    borderRadius: 3,
    zIndex: Z_INDEX.tooltip,
    transition: 'opacity 0.3s',
    wordBreak: 'break-word',
    maxWidth: 400
  },
  ({ zIndex }) => ({
    zIndex: zIndex ? zIndex : Z_INDEX.tooltip
  })
);

const TooltipArrow = glamorous.div(
  {
    position: 'absolute',
    height: 16,
    width: 16,
    ':before': {
      borderStyle: 'solid',
      content: ' ',
      display: 'block',
      height: 0,
      margin: 'auto',
      width: 0
    },
    ':after': {
      borderStyle: 'solid',
      content: ' ',
      display: 'block',
      height: 0,
      margin: 'auto',
      position: 'absolute',
      width: 0
    }
  },
  ({ arrowPlacement }) => {
    let styles = [];

    if (arrowPlacement === 'bottom') {
      styles.push({
        top: 0,
        left: 0,
        marginTop: -6,
        ':before': {
          borderColor: `transparent transparent ${
            COLORS.cadetBlue
          } transparent`,
          borderWidth: '0 8px 6px 8px',
          position: 'absolute',
          top: -1
        },
        ':after': {
          borderColor: 'transparent transparent white transparent',
          borderWidth: '0 8px 6px 8px'
        }
      });
    }

    if (arrowPlacement === 'top') {
      styles.push({
        bottom: 0,
        left: 0,
        marginBottom: -16,
        ':before': {
          borderColor: `${
            COLORS.cadetBlue
          } transparent transparent transparent`,
          borderWidth: '6px 8px 0 8px',
          position: 'absolute',
          top: 1
        },
        ':after': {
          borderColor: 'white transparent transparent transparent',
          borderWidth: '6px 8px 0 8px'
        }
      });
    }

    if (arrowPlacement === 'right') {
      styles.push({
        left: 0,
        marginLeft: -12,
        ':before': {
          borderColor: `transparent ${
            COLORS.cadetBlue
          } transparent transparent`,
          borderWidth: '8px 6px 8px 0'
        },
        ':after': {
          borderColor: 'transparent white transparent transparent',
          borderWidth: '8px 6px 8px 0',
          left: 6,
          top: 0
        }
      });
    }

    if (arrowPlacement === 'left') {
      styles.push({
        right: 0,
        marginRight: -12,
        ':before': {
          borderColor: `transparent transparent transparent ${
            COLORS.cadetBlue
          }`,
          borderWidth: '8px 0 8px 6px'
        },
        ':after': {
          borderColor: 'transparent transparent transparent white',
          borderWidth: '8px 0 8px 6px',
          left: 4,
          top: 0
        }
      });
    }

    return styles;
  }
);

const Tooltip = ({
  tooltip,
  children,
  containerCss,
  triggerClassName,
  zIndex,
  ...props
}) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      getTooltipProps,
      getArrowProps,
      tooltipRef,
      arrowRef,
      placement
    }) => (
      <TooltipContainer
        {...getTooltipProps({
          innerRef: tooltipRef,
          css: containerCss,
          zIndex
        })}
      >
        <TooltipArrow
          {...getArrowProps({
            innerRef: arrowRef,
            arrowPlacement: placement
          })}
        />
        {tooltip}
      </TooltipContainer>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: triggerClassName
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

export default Tooltip;
