import React from 'react';
import { css } from 'glamor';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from './Tooltip'
import { COLORS } from '../constants/theme';

const InfoTooltip = ({ text, iconColor }) => (
  <Tooltip
    tooltip={text}
    placement="top"
    triggerClassName={css({
      verticalAlign: 'middle',
      marginLeft: 10,
      lineHeight: 0,
    })}
  >
    <InfoIcon fontSize="small" htmlColor={iconColor || COLORS.cadetBlue} />
  </Tooltip>
);

export default InfoTooltip;
