import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Div, H3 } from 'glamorous';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withErrorBoundary } from '@mvpf/platform-shared/components/ErrorBoundary';
import Notification from './types/Notification';
import NotificationList from './NotificationList';
import { SPACING } from '@mvpf/platform-shared/constants/theme';
import { generateNotificationQueryFilter } from '../../utils/notification';
import { headerQuery } from '../Header';

class Notifications extends Component {
  state = {
    filter: 'unread'
  };

  handleMarkRead = id => {
    const { filter } = this.state;

    this.props.markReadNotification({ id, filter });
  };

  handleMarkUnread = id => {
    const { filter } = this.state;

    this.props.markUnreadNotification({ id, filter });
  };

  handleChangeFilter = (event, newFilter) => {
    const { filter } = this.state;

    if (newFilter && filter !== newFilter) {
      this.setState({ filter: newFilter }, () => {
        const queryFilter = generateNotificationQueryFilter(newFilter);

        this.props.refetch({
          filter: queryFilter
        });
      });
    }
  };

  handleShowMore = () => {
    const { filter } = this.state;

    this.props.loadMoreNotifications(filter);
  };

  render() {
    const { filter } = this.state;
    const { open, onClose, error, loading, loadingMore, user } = this.props;

    if (error) {
      throw new Error(error);
    }

    return (
      <Drawer open={open} anchor="right" onClose={onClose}>
        <Div width={500}>
          <Div padding={SPACING}>
            <Div
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <H3 margin={0} fontSize={20} fontWeight="normal">
                Notifications {user && `(${user.notificationsCount})`}
              </H3>

              <Div display="flex" alignItems="center">
                <ToggleButtonGroup
                  exclusive
                  value={filter}
                  onChange={this.handleChangeFilter}
                >
                  <ToggleButton value="unread">Unread</ToggleButton>
                  <ToggleButton value="read">Read</ToggleButton>
                  <ToggleButton value="all">All</ToggleButton>
                </ToggleButtonGroup>
              </Div>

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Div>
          </Div>

          <NotificationList
            loading={loading}
            loadingMore={loadingMore}
            notifications={user && user.notificationsConnection.nodes}
            hasMoreNotifications={
              user && user.notificationsConnection.pageInfo.hasNextPage
            }
            onShowMore={this.handleShowMore}
            onMarkRead={this.handleMarkRead}
            onMarkUnread={this.handleMarkUnread}
          />
        </Div>
      </Drawer>
    );
  }
}

export const notificationsQuery = gql`
  query notifications($filter: NotificationFilter) {
    user {
      id
      notificationsConnection(filter: $filter, first: 10) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...SidebarNotification
        }
      }
      notificationsCount(filter: $filter)
    }
  }
  ${Notification.fragments.notification}
`;

const moreNotificationsQuery = gql`
  query moreNotifications(
    $filter: NotificationFilter
    $cursor: String
    $first: Int
  ) {
    user {
      id
      notificationsConnection(filter: $filter, cursor: $cursor, first: $first) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...SidebarNotification
        }
      }
    }
  }
  ${Notification.fragments.notification}
`;

const markReadNotificationMutation = gql`
  mutation markReadNotification($id: ID!) {
    markNotificationAsRead(id: $id, isRead: true) {
      id
      isRead
    }
  }
`;

const markUnreadNotificationMutation = gql`
  mutation markUnreadNotification($id: ID!) {
    markNotificationAsRead(id: $id, isRead: false) {
      id
      isRead
    }
  }
`;

export default compose(
  graphql(notificationsQuery, {
    options: {
      variables: {
        filter: generateNotificationQueryFilter('unread')
      },
      fetchPolicy: 'cache-and-network'
    },
    props({
      data: { loading, error, networkStatus, user, refetch, fetchMore }
    }) {
      return {
        loading: networkStatus < 3,
        loadingMore: networkStatus < 4,
        error,
        user,
        refetch,
        loadMoreNotifications(filter) {
          return fetchMore({
            query: moreNotificationsQuery,
            variables: {
              filter: generateNotificationQueryFilter(filter),
              cursor: user.notificationsConnection.pageInfo.endCursor,
              first: 20
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousNotifications =
                previousResult.user.notificationsConnection.nodes;
              const newNotifications =
                fetchMoreResult.user.notificationsConnection.nodes;
              const newPageInfo =
                fetchMoreResult.user.notificationsConnection.pageInfo;

              return {
                user: {
                  ...previousResult.user,
                  notificationsConnection: {
                    ...previousResult.user.notificationsConnection,
                    pageInfo: newPageInfo,
                    nodes: [...previousNotifications, ...newNotifications]
                  }
                }
              };
            }
          });
        }
      };
    }
  }),
  graphql(markReadNotificationMutation, {
    props: ({ mutate }) => ({
      markReadNotification: ({ id, filter }) =>
        mutate({
          variables: {
            id
          },

          optimisticResponse: {
            __typename: 'Mutation',
            updateNotification: {
              __typename: 'Notification',
              id,
              read: true
            }
          },

          update: store => {
            if (filter === 'unread') {
              const queryFilter = generateNotificationQueryFilter(filter);

              const data = store.readQuery({
                query: notificationsQuery,
                variables: {
                  filter: queryFilter
                }
              });

              data.user.notificationsConnection.nodes = data.user.notificationsConnection.nodes.filter(
                notification => notification.id !== id
              );

              data.user.notificationsCount = data.user.notificationsCount - 1;

              store.writeQuery({
                query: notificationsQuery,
                variables: {
                  filter: queryFilter
                },
                data
              });
            }

            const headerData = store.readQuery({
              query: headerQuery
            });

            headerData.user.notificationsCount =
              headerData.user.notificationsCount - 1;

            store.writeQuery({
              query: headerQuery,
              data: headerData
            });
          }
        })
    })
  }),
  graphql(markUnreadNotificationMutation, {
    props: ({ mutate }) => ({
      markUnreadNotification: ({ id, filter }) =>
        mutate({
          variables: {
            id
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateNotification: {
              __typename: 'Notification',
              id,
              read: false
            }
          },

          update: store => {
            if (filter === 'read') {
              const queryFilter = generateNotificationQueryFilter(filter);

              const data = store.readQuery({
                query: notificationsQuery,
                variables: {
                  filter: queryFilter
                }
              });

              data.user.notificationsConnection.nodes = data.user.notificationsConnection.nodes.filter(
                notification => notification.id !== id
              );

              data.user.notificationsCount = data.user.notificationsCount - 1;

              store.writeQuery({
                query: notificationsQuery,
                variables: {
                  filter: queryFilter
                },
                data
              });
            }

            const headerData = store.readQuery({
              query: headerQuery
            });

            headerData.user.notificationsCount =
              headerData.user.notificationsCount + 1;

            store.writeQuery({
              query: headerQuery,
              data: headerData
            });
          }
        })
    })
  })
)(withErrorBoundary(Notifications));
