import React, { Component } from 'react';
import Downshift from 'downshift';
import { css } from 'glamor';
import VirtualList from 'react-tiny-virtual-list';
import matchSorter from 'match-sorter';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import {
  AutocompleteWrapper,
  AutocompleteList,
  AutocompleteListInfo
} from './AutocompleteElements';
import countries from '../constants/countries';
import Z_INDEX from '../constants/z-index';

class CountryAutocomplete extends Component {
  popperNode = React.createRef();

  render() {
    const { inputProps, InputLabelProps, ...rest } = this.props;

    return (
      <Downshift {...rest}>
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          openMenu
        }) => {
          const items = inputValue
            ? matchSorter(countries, inputValue, { keys: ['name'] })
            : countries;

          return (
            <AutocompleteWrapper
              {...getRootProps({
                refKey: 'innerRef',
                fullWidth: inputProps && inputProps.fullWidth
              })}
            >
              <TextField
                {...getInputProps({
                  inputRef: this.popperNode,
                  label: 'Country',
                  onFocus: openMenu,
                  InputLabelProps: InputLabelProps,
                  ...inputProps
                })}
              />

              <Popper
                open={isOpen}
                anchorEl={this.popperNode.current}
                className={`${css({ zIndex: Z_INDEX.autocomplete })}`}
              >
                <div
                  {...(isOpen
                    ? getMenuProps({}, { suppressRefError: true })
                    : {})}
                >
                  <AutocompleteList
                    width={
                      this.popperNode.current
                        ? this.popperNode.current.clientWidth
                        : null
                    }
                  >
                    {items.length === 0 ? (
                      <AutocompleteListInfo>
                        No countries found.
                      </AutocompleteListInfo>
                    ) : (
                      <VirtualList
                        width="100%"
                        // scrollToIndex={highlightedIndex || 0}
                        height={items.length < 6 ? items.length * 46 : 284}
                        itemCount={items.length}
                        itemSize={46}
                        renderItem={({ index, style }) => (
                          <MenuItem
                            {...getItemProps({
                              key: index,
                              index,
                              item: items[index].name,
                              selected: highlightedIndex === index,
                              style: {
                                ...style,
                                width: 'calc(100% - 32px)',
                                height: 24,
                                fontWeight:
                                  selectedItem === items[index].name
                                    ? 'bold'
                                    : 'normal'
                              }
                            })}
                          >
                            {items[index].name}
                          </MenuItem>
                        )}
                      />
                    )}
                  </AutocompleteList>
                </div>
              </Popper>
            </AutocompleteWrapper>
          );
        }}
      </Downshift>
    );
  }
}

export default CountryAutocomplete;
