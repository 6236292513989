import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { css } from 'glamor';
import { Div } from 'glamorous';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import {
  AutocompleteWrapper,
  AutocompleteList,
  AutocompleteListInfo
} from './AutocompleteElements';
import {
  GreyBox,
  GreyBoxDeleteButton,
} from '@mvpf/platform-shared/components/GreyBox';
import debounce from 'lodash/debounce';
import Z_INDEX from '../constants/z-index';
import { USER_POSITION_LABELS, } from '@mvpf/platform-shared/constants/user';

const itemToString = item => (item ? item.name : '');

class ProjectsAutocomplete extends Component {
  state = this.getState({
    inputValue: '',
    debouncedValue: '',
    selectedItem: []
  });

  popperNode = React.createRef();

  // Gets the state based on internal state or props
  // If a state value is passed via props, then that
  // is the value given, otherwise it's retrieved from
  // stateToMerge
  getState(stateToMerge = this.state) {
    return Object.keys(stateToMerge).reduce((state, key) => {
      state[key] = this.isControlledProp(key)
        ? this.props[key]
        : stateToMerge[key];
      return state;
    }, {});
  }

  // This determines whether a prop is a "controlled prop" meaning it is
  // state which is controlled by the outside of this component rather
  // than within this component.
  isControlledProp(key) {
    return this.props[key] !== undefined;
  }

  // any piece of our state can live in two places:
  // 1. Uncontrolled: it's internal (this.state)
  //    We will call this.setState to update that state
  // 2. Controlled: it's external (this.props)
  //
  // In addition, we'll call this.props.onChange if the
  // selectedItem is changed.
  internalSetState = stateToSet => {
    const { onChange } = this.props;

    return this.setState(
      state => {
        return Object.keys(stateToSet).reduce((state, key) => {
          if (!this.isControlledProp(key)) {
            state[key] = stateToSet[key];
          }
          return state;
        }, {});
      },
      () => {
        if (stateToSet.hasOwnProperty('selectedItem')) {
          onChange && onChange(stateToSet.selectedItem);
        }
      }
    );
  };

  handleInputChange = event => {
    this.internalSetState({ inputValue: event.target.value });
  };

  handleInputBlur = () => {
    const { inputProps } = this.props;

    this.internalSetState({ inputValue: '' });
    inputProps && inputProps.blur && inputProps.blur();
  };

  handleChange = item => {
    let { selectedItem } = this.getState();

    if (!selectedItem.find(sel => sel.id === item.id)) {
      selectedItem = [
        ...selectedItem, item
      ];
    }

    this.internalSetState({ inputValue: '', debouncedValue: '', selectedItem });
  };

  handleDelete = item => () => {
    let { selectedItem } = this.getState();

    selectedItem = [...selectedItem];
    selectedItem.splice(selectedItem.indexOf(item), 1);

    this.internalSetState({
      selectedItem
    });
  };

  setDebounceValue = debounce(changes => {
    this.internalSetState({ debouncedValue: changes.inputValue });
  }, 500);

  handleStateChange = changes => {
    if (
      // need to check the type because on rerenders downshift will fire an action
      // that selectedItems changed because it does only shallow comparison
      changes.type === Downshift.stateChangeTypes.changeInput &&
      changes.hasOwnProperty('inputValue')
    ) {
      this.setDebounceValue(changes);
    }
  };

  render() {
    const { inputValue, selectedItem, debouncedValue } = this.getState();
    const {
      selectedItem: _,
      onChange,
      inputProps,
      groupId,
      excludeGroupId,
      label,
      placeholder,
      ...rest
    } = this.props;

    const filter = {
      name_contains: debouncedValue
    };

    if (groupId) {
      filter.groupId = groupId;
    }

    if (excludeGroupId) {
      filter.groupId_not_in = excludeGroupId;
    }

    return (
      <Downshift
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
        onStateChange={this.handleStateChange}
        itemToString={itemToString}
        {...rest}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <Div {...getRootProps({ refKey: 'innerRef' })}>
              <AutocompleteWrapper fullWidth={inputProps && inputProps.fullWidth}>
                <TextField
                  {...getInputProps({
                    ...inputProps,
                    inputRef: this.popperNode,
                    label: label,
                    placeholder: placeholder,
                    onChange: this.handleInputChange,
                    onBlur: this.handleInputBlur,
                  })}
                />

                <Popper
                  open={isOpen && !!debouncedValue}
                  anchorEl={this.popperNode.current}
                  className={`${css({ zIndex: Z_INDEX.autocomplete })}`}
                >
                  <div
                    {...(isOpen
                      ? getMenuProps({}, { suppressRefError: true })
                      : {})}
                  >
                    <AutocompleteList
                      width={
                        this.popperNode.current
                          ? this.popperNode.current.clientWidth
                          : null
                      }
                    >
                      <Query query={openPositionsQuery}
                        variables={{
                          filter: {
                            status: 'OPEN',
                            idNotIn: selectedItem.map(job => job.id),
                          },
                          pagination: {
                            skip: 0,
                            first: 500,
                            orderBy: [{ field: 'project.name', order: 'ASC' }]
                          }
                        }} >
                        {({ loading, error, data: { allJobs = [] } = {} }) => {
                          const jobs = inputValue
                            ? matchSorter(allJobs, inputValue, { keys: ['title', 'customTitle', 'project.name'] })
                            : allJobs;

                          if (loading) {
                            return (
                              <AutocompleteListInfo>
                                Loading...
                              </AutocompleteListInfo>
                            );
                          }

                          if (error) {
                            return (
                              <AutocompleteListInfo>
                                Error! ${error.message}
                              </AutocompleteListInfo>
                            );
                          }

                          if (jobs.length === 0) {
                            return (
                              <AutocompleteListInfo>
                                No jobs found.
                              </AutocompleteListInfo>
                            );
                          }

                          return jobs.map((item, index) => (
                            <MenuItem
                              {...getItemProps({
                                key: item.id,
                                index,
                                onClick: () => this.handleChange(item),
                                item,
                                selected: highlightedIndex === index,
                                style: {
                                  fontWeight:
                                    selectedItem && selectedItem === item
                                      ? 'bold'
                                      : 'normal'
                                }
                              })}
                            >
                              {item.project.name} | {USER_POSITION_LABELS[item.title]}
                              {item.customTitle && ` | ${item.customTitle}`}
                            </MenuItem>
                          ));
                        }}
                      </Query>
                    </AutocompleteList>
                  </div>
                </Popper>
              </AutocompleteWrapper>
              <Div display="flex" flexDirection="column" width="100%">
                {selectedItem.map(job => (
                  <GreyBox key={job.id} css={{ display: 'flex' }} >
                    <Div width="40%">
                      {job.project.name}
                    </Div>
                    <Div width="55%">
                      {USER_POSITION_LABELS[job.title]}
                      {job.customTitle && ` | ${job.customTitle}`}
                    </Div>
                    <GreyBoxDeleteButton onClick={this.handleDelete(job)} />
                  </GreyBox>
                ))}
              </Div>
            </Div>
          );
        }}
      </Downshift >
    );
  }
}

const openPositionsQuery = gql`
  query openPositions($filter: JobFilter, $pagination: Pagination) {
    allJobs(filter: $filter, pagination: $pagination) {
      id
      title
      customTitle
      project {
        id
        name
      }
    }
    allJobsCount(filter: $filter)
  }
`;

export default ProjectsAutocomplete;
