import React from 'react';
import CountryAutocomplete from './CountryAutocomplete';

const CountryAutocompleteAdapter = ({ input, meta, inputProps, ...rest }) => (
  <CountryAutocomplete
    onChange={selection => input.onChange(selection)}
    selectedItem={input.value}
    inputProps={{
      ...inputProps,
      onBlur: () => input.onBlur(),
      helperText: meta.touched ? meta.error : undefined,
      error: meta.error && meta.touched
    }}
    {...rest}
  />
);

export default CountryAutocompleteAdapter;
