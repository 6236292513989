import React, { Component } from 'react';
import { func, object, string, array } from 'prop-types';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import makeAsyncScriptLoader from 'react-async-script';

class GooglePlacesAutocompleteServiceWrapper extends Component {
  state = {
    scriptLoaded: false
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.google && this.props.google) {
      this.init();
    }
  }

  init = () => {
    const { google } = this.props;

    if (google) {
      this.setState({
        scriptLoaded: true
      });
    }
  };

  render() {
    const { scriptLoaded } = this.state;

    if (!scriptLoaded) {
      return null;
    }

    return <GooglePlacesAutocompleteService {...this.props} />;
  }
}

class GooglePlacesAutocompleteService extends Component {
  state = {
    results: undefined,
    loading: false
  };

  autocompleteService = new this.props.google.maps.places.AutocompleteService();

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.input, this.props.input) ||
      !isEqual(prevProps.types, this.props.types) ||
      !isEqual(
        prevProps.componentRestrictions,
        this.props.componentRestrictions
      )
    ) {
      this.fetchData();
    }
  }

  getResults = debounce(() => {
    const { input, types, componentRestrictions } = this.props;

    this.autocompleteService.getPlacePredictions(
      {
        input,
        types,
        componentRestrictions
      },
      predictions => {
        this.setState({
          results: predictions,
          loading: false
        });
      }
    );
  }, 200);

  fetchData = () => {
    this.setState({
      loading: true
    });

    this.getResults();
  };

  render() {
    const { children } = this.props;
    const { results, loading } = this.state;

    return children({
      results,
      loading
    });
  }
}

GooglePlacesAutocompleteService.propTypes = {
  children: func.isRequired,
  google: object.isRequired,
  input: string.isRequired,
  types: array,
  componentRestrictions: object
};

export default makeAsyncScriptLoader(
  `https://maps.googleapis.com/maps/api/js?key=${
    process.env.REACT_APP_GOOGLE_API_KEY
  }&libraries=places`,
  {
    globalName: 'google'
  }
)(GooglePlacesAutocompleteServiceWrapper);
