import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import glamorous, { Div } from 'glamorous';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loading from '@mvpf/platform-shared/components/Loading';
import ErrorBoundary from '@mvpf/platform-shared/components/ErrorBoundary';
import debounce from 'lodash/debounce';
import {
  COLORS,
  SPACING,
  FONT_FAMILY
} from '@mvpf/platform-shared/constants/theme';

const SearchField = glamorous.input({
  display: 'block',
  width: '100%',
  padding: 0,
  fontFamily: FONT_FAMILY,
  fontSize: 18,
  color: COLORS.text,
  backgroundColor: 'transparent',
  border: 'none',
  ':focus': {
    outline: 0
  },
  '&::placeholder': {
    color: COLORS.cadetBlue
  }
});

class GlobalSearch extends Component {
  state = {
    search: '',
    debouncedSearch: ''
  };

  searchInput = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.open && this.props.open !== prevProps.open) {
      setTimeout(() => {
        this.searchInput.current.focus();
      }, 0);
    }
  }

  setDebounceValue = debounce(value => {
    this.setState({ debouncedSearch: value });
  }, 500);

  handleSearchFieldChange = event => {
    this.setState(
      {
        search: event.target.value
      },
      () => {
        this.setDebounceValue(this.state.search);
      }
    );
  };

  closeAndReset = () => {
    this.props.onClose();

    this.setState({
      search: '',
      debouncedSearch: ''
    });
  };

  render() {
    const { open, onClose, history } = this.props;
    const { search, debouncedSearch } = this.state;

    return (
      <Drawer open={open} anchor="right" onClose={this.closeAndReset}>
        <Div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={SPACING}
          width={400}
        >
          <SearchField
            value={search}
            onChange={this.handleSearchFieldChange}
            placeholder="Search... (Type at least 2 characters)"
            innerRef={this.searchInput}
          />
          <IconButton onClick={onClose}>
            <CloseIcon htmlColor={COLORS.cadetBlue} />
          </IconButton>
        </Div>

        <Div>
          {debouncedSearch.trim().length > 1 && (
            <ErrorBoundary>
              <Query
                query={globalSearchQuery}
                variables={{
                  search: debouncedSearch
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <Loading />;
                  }

                  if (error) {
                    throw new Error(error);
                  }

                  return (
                    <Fragment>
                      {data.allUsers.length > 0 && (
                        <List subheader={<ListSubheader>Elites</ListSubheader>}>
                          {data.allUsers.map(user => (
                            <ListItem
                              key={user.id}
                              button
                              onClick={() => {
                                history.push(`/elites/${user.id}`);
                                this.closeAndReset();
                              }}
                            >
                              <ListItemText
                                primary={`${user.firstName} ${user.lastName}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}

                      {data.allProjects.length > 0 && (
                        <List
                          subheader={<ListSubheader>Projects</ListSubheader>}
                        >
                          {data.allProjects.map(project => (
                            <ListItem
                              key={project.id}
                              button
                              onClick={() => {
                                history.push(`/projects/${project.id}`);
                                this.closeAndReset();
                              }}
                            >
                              <ListItemText primary={project.name} />
                            </ListItem>
                          ))}
                        </List>
                      )}

                      {data.allCompanies.length > 0 && (
                        <List
                          subheader={<ListSubheader>Companies</ListSubheader>}
                        >
                          {data.allCompanies.map(company => (
                            <ListItem
                              key={company.id}
                              button
                              onClick={() => {
                                history.push(`/companies/${company.id}`);
                                this.closeAndReset();
                              }}
                            >
                              <ListItemText primary={company.name} />
                            </ListItem>
                          ))}
                        </List>
                      )}

                      {data.allUsers.length === 0 &&
                        data.allProjects.length === 0 &&
                        data.allCompanies.length === 0 && (
                          <Div
                            padding={20}
                            textAlign="center"
                            fontWeight="bold"
                          >
                            No matching search results.
                          </Div>
                        )}
                    </Fragment>
                  );
                }}
              </Query>
            </ErrorBoundary>
          )}
        </Div>
      </Drawer>
    );
  }
}

const globalSearchQuery = gql`
  query globalSearch($search: String!) {
    allUsers(filter: { fullName_contains: $search, roles: "elite" }) {
      id
      firstName
      lastName
      avatar {
        id
        url
      }
    }
    allProjects(filter: { name_contains: $search }) {
      id
      name
    }
    allCompanies(filter: { name_contains: $search }) {
      id
      name
    }
  }
`;

export default withRouter(GlobalSearch);
