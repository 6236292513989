import React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import TextFieldAdapter from '@mvpf/platform-shared/components/TextFieldAdapter';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ProgressButton from '@mvpf/platform-shared/components/ProgressButton';
import Loading from '@mvpf/platform-shared/components/Loading';
import FormInsideDialogFix from '@mvpf/platform-shared/components/FormInsideDialogFix';
import { required } from '@mvpf/platform-shared/utils/validation';
import ErrorMessage from '@mvpf/platform-shared/components/ErrorMessage';
import ErrorBoundary from '@mvpf/platform-shared/components/ErrorBoundary';

const NewCompanyDialog = ({ onClose, history }) => (
  <Dialog disableBackdropClick open onClose={onClose} fullWidth>
    <DialogTitle>New Company</DialogTitle>

    <ErrorBoundary>
      <Query query={allSkillGroupsQuery}>
        {({ loading, error, data }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) {
            throw new Error(error);
          }

          return (
            <Mutation mutation={createCompanyMutation}>
              {createCompany => (
                <Form
                  onSubmit={async values => {
                    try {
                      const response = await createCompany({
                        variables: {
                          ...values
                        }
                      });

                      onClose();
                      history.push(
                        `/companies/${response.data.createCompany.id}`
                      );
                    } catch (error) {
                      return {
                        [FORM_ERROR]: error.graphQLErrors[0].message
                      };
                    }
                  }}
                  render={({ handleSubmit, submitting, submitError }) => (
                    <FormInsideDialogFix onSubmit={handleSubmit}>
                      <DialogContent>
                        {submitError && (
                          <ErrorMessage>{submitError}</ErrorMessage>
                        )}

                        <Field
                          name="name"
                          component={TextFieldAdapter}
                          validate={required}
                          label="Name"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-name-input'
                          }}
                        />

                        <Query
                          query={allSkillsQueryByName}
                          variables={{
                            groupId: data.allSkillGroups[0].id
                          }}
                        >
                          {({ data, loading }) =>
                            !loading && (
                              <Field
                                name="industry"
                                component={TextFieldAdapter}
                                select
                                label="Industry"
                                fullWidth
                                data-cy="company-industry-select"
                              >
                                {data.allSkills.map(skill => (
                                  <MenuItem key={skill.id} value={skill.name}>
                                    {skill.name}
                                  </MenuItem>
                                ))}
                              </Field>
                            )
                          }
                        </Query>

                        <Field
                          name="entityName"
                          component={TextFieldAdapter}
                          label="Entity Name"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-entity-name-input'
                          }}
                        />

                        <Field
                          name="entityAddress1"
                          component={TextFieldAdapter}
                          label="Entity Address"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-entity-address-1-input'
                          }}
                        />

                        <Field
                          name="entityAddress2"
                          component={TextFieldAdapter}
                          label="Entity Address 2"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-entity-address-2-input'
                          }}
                        />

                        <Field
                          name="entityCity"
                          component={TextFieldAdapter}
                          label="City"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-entity-city-input'
                          }}
                        />

                        <Field
                          name="entityPostcode"
                          component={TextFieldAdapter}
                          label="Postcode"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-entity-post-code-input'
                          }}
                        />

                        <Field
                          name="entityCountry"
                          component={TextFieldAdapter}
                          label="Country"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-entity-country-input'
                          }}
                        />

                        <Field
                          name="website"
                          component={TextFieldAdapter}
                          label="Website"
                          type="url"
                          margin="normal"
                          fullWidth
                          inputProps={{
                            'data-cy': 'company-website-input'
                          }}
                        />
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>

                        <ProgressButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          loading={submitting}
                          data-cy="company-add-button"
                        >
                          Add
                        </ProgressButton>
                      </DialogActions>
                    </FormInsideDialogFix>
                  )}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    </ErrorBoundary>
  </Dialog>
);

const allSkillGroupsQuery = gql`
  query allSkillGroups {
    allSkillGroups(
      orderBy: [{ field: "name", order: ASC }]
      filter: { name: "Industry & Product" }
    ) {
      id
    }
  }
`;

const createCompanyMutation = gql`
  mutation createCompany(
    $name: String!
    $industry: String
    $entityName: String
    $entityAddress1: String
    $entityAddress2: String
    $entityCity: String
    $entityPostcode: String
    $entityCountry: String
    $website: String
  ) {
    createCompany(
      name: $name
      industry: $industry
      entityName: $entityName
      entityAddress1: $entityAddress1
      entityAddress2: $entityAddress2
      entityCity: $entityCity
      entityPostcode: $entityPostcode
      entityCountry: $entityCountry
      website: $website
    ) {
      id
      name
      industry
      entityName
      entityAddress1
      entityAddress2
      entityCity
      entityPostcode
      entityCountry
      website
    }
  }
`;

const allSkillsQueryByName = gql`
  query allSkills($groupId: ID) {
    allSkills(
      orderBy: [{ field: "name", order: ASC }]
      filter: { groupId: $groupId }
    ) {
      id
      name
    }
  }
`;

export default withRouter(NewCompanyDialog);
