import { COLORS } from './theme';

export const PROJECT_ENTITIES = [
  'MVPF',
  'GTS',
];

export const PROJECT_STATUSES = [
  'ACTIVE',
  'LATE_PAYMENT',
  'IN_RESOLUTION',
  'CANCELED',
  'ARCHIVED'
];

export const PROJECT_STATUS_LABELS = {
  ACTIVE: 'Active',
  LATE_PAYMENT: 'Late Payment',
  IN_RESOLUTION: 'In-Resolution',
  CANCELED: 'Canceled',
  ARCHIVED: 'Archived'
};

export const PROJECT_STATUS_COLORS = {
  ACTIVE: COLORS.jungleGreen,
  LATE_PAYMENT: COLORS.yellowOrange,
  IN_RESOLUTION: COLORS.yellowOrange,
  CANCELED: COLORS.burntSienna,
  ARCHIVED: COLORS.cadetBlue
};

export const PROJECT_PHASES_BY_SERVICE_TYPE = {
  MANAGED_TEAM: [
    'NEW',
    'BRIEF',
    'QUOTE',
    'AGREEMENT',
    'TEAM_ASSEMBLY',
    'IN_PROGRESS',
    'HANDOVER',
    'DELIVERED'
  ],
  FIXED_SCOPE: [
    'NEW',
    'BRIEF',
    'QUOTE',
    'AGREEMENT',
    'SCOPING',
    'SCOPE_ADJUSTMENT',
    'IN_PROGRESS',
    'HANDOVER',
    'DELIVERED'
  ],
  SECURITY: [
    'NEW',
    'BRIEF',
    'QUOTE',
    'AGREEMENT',
    'IN_PROGRESS',
    'HANDOVER',
    'DELIVERED'
  ],
  OTHER: [
    'NEW',
    'BRIEF',
    'QUOTE',
    'AGREEMENT',
    'IN_PROGRESS',
    'HANDOVER',
    'DELIVERED'
  ]
};

export const PROJECT_PHASE_LABELS = {
  NEW: 'New',
  BRIEF: 'Brief',
  QUOTE: 'Quote',
  AGREEMENT: 'Agreement',
  TEAM_ASSEMBLY: 'Team Assembly',
  IN_PROGRESS: 'In Progress',
  SCOPING: 'Scoping',
  SCOPE_ADJUSTMENT: 'Scope Adjustment (Agreement)',
  HANDOVER: 'Handover (off-board)',
  DELIVERED: 'Delivered'
};

export const PROJECT_COMPANY_TYPES = ['STARTUP', 'SCALEUP', 'ENTERPRISE'];

export const PROJECT_COMPANY_TYPE_LABELS = {
  STARTUP: 'Startup',
  SCALEUP: 'Scale-up',
  ENTERPRISE: 'Enterprise'
};

export const PROJECT_POSITION_TYPES = ['RETAINER', 'MILESTONE'];

export const PROJECT_POSITION_TYPE_LABELS = {
  RETAINER: 'Retainer',
  MILESTONE: 'Milestone'
};

export const PROJECT_POSITION_EFFORT = ['FULL_TIME', 'PART_TIME', 'LIMITED'];

export const PROJECT_POSITION_EFFORT_LABELS = {
  FULL_TIME: 'Full time',
  PART_TIME: 'Part time',
  LIMITED: 'Limited'
};

export const PROJECT_SERVICE_TYPES = [
  'FIXED_SCOPE',
  'MANAGED_TEAM',
  'SECURITY',
  'OTHER'
];

export const PROJECT_SERVICE_TYPE_LABELS = {
  FIXED_SCOPE: 'Fixed Scope',
  MANAGED_TEAM: 'Managed Team',
  SECURITY: 'Security',
  OTHER: 'Other'
};

export const PROJECT_MEMBER_STATUS_LABELS = {
  ACCEPTED: 'Accepted',
  SIGNATURE: 'Signature',
  MEMBER: 'Member',
  REMOVED: 'Removed',
  DONE: 'Done'
};

export const PROJECT_MEMBER_ROLE_LABELS = {
  TECH_LEAD: 'Tech Lead',
  PROJECT_LEAD: 'Project Lead'
};
