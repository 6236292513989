import React from 'react';
import { css } from 'glamor';
import glamorous from 'glamorous';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Clear';
import { COLORS } from '@mvpf/platform-shared/constants/theme';

export const AutocompleteWrapper = glamorous.div(
  {
    position: 'relative',
    display: 'inline-flex'
  },
  ({ fullWidth }) => ({
    width: fullWidth ? '100%' : 'auto'
  })
);

export const AutocompleteList = glamorous(Paper, {
  withProps: { square: true }
})(
  {
    maxHeight: '300px',
    overflowY: 'auto'
  },
  ({ width }) => ({
    width
  })
);

export const AutocompleteListInfo = glamorous.div({
  padding: 16,
  color: COLORS.cadetBlue
});

export const AutocompleteClearButton = props => (
  <IconButton
    className={`${css({
      position: 'absolute',
      top: 0,
      right: 0
    })}`}
    {...props}
  >
    <DeleteIcon fontSize="small" htmlColor={COLORS.cadetBlue} />
  </IconButton>
);
