import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const CurrencyNumberTextFieldAdapter = ({ input, meta, ...rest }) => (
  <NumberFormat
    {...rest}
    value={input.value}
    onValueChange={values => {
      input.onChange(values.value);
    }}
    isNumericString
    thousandSeparator
    decimalScale={2}
    allowNegative={false}
    customInput={TextField}
    onFocus={input.onFocus}
    onBlur={input.onBlur}
    helperText={meta.touched ? meta.error : undefined}
    error={meta.error && meta.touched}
  />
);

export default CurrencyNumberTextFieldAdapter;
