import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import TextFieldAdapter from '@mvpf/platform-shared/components/TextFieldAdapter';
import Button from '@material-ui/core/Button';
import ProgressButton from '@mvpf/platform-shared/components/ProgressButton';
import ErrorMessage from '@mvpf/platform-shared/components/ErrorMessage';
import ErrorBoundary from '@mvpf/platform-shared/components/ErrorBoundary';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { Row, Col } from '@mvpf/platform-shared/components/Grid';
import { required } from '@mvpf/platform-shared/utils/validation';
import {
  PROJECT_STATUSES,
  PROJECT_STATUS_LABELS,
  PROJECT_PHASES_BY_SERVICE_TYPE,
  PROJECT_PHASE_LABELS,
  PROJECT_SERVICE_TYPES,
  PROJECT_SERVICE_TYPE_LABELS,
  PROJECT_ENTITIES,
} from '@mvpf/platform-shared/constants/project';

const NewProjectDialog = ({ onClose, history }) => (
  <Dialog disableBackdropClick open onClose={onClose} fullWidth>
    <ErrorBoundary>
      <Mutation mutation={createProjectMutation}>
        {createProject => (
          <Form
            onSubmit={async values => {
              try {
                const response = await createProject({
                  variables: {
                    ...values
                  }
                });

                onClose();
                history.push(`/projects/${response.data.createProject.id}`);
              } catch (error) {
                return { [FORM_ERROR]: error.graphQLErrors[0].message };
              }
            }}
            render={({ handleSubmit, submitting, values, submitError }) => (
              <form onSubmit={handleSubmit} data-cy="new-project-form">
                <DialogTitle>New Project</DialogTitle>

                <DialogContent>
                  {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                  <Row>
                    <Col size={6}>
                      <Field
                        name="name"
                        component={TextFieldAdapter}
                        validate={required}
                        label="Name"
                        margin="normal"
                        fullWidth
                        inputProps={{
                          'data-cy': 'project-name-input'
                        }}
                      />
                    </Col>
                    <Col size={6}>
                      <Field
                        name="status"
                        component={TextFieldAdapter}
                        validate={required}
                        select
                        label="Status"
                        margin="normal"
                        fullWidth
                        data-cy="project-status-select"
                      >
                        {PROJECT_STATUSES.map(status => (
                          <MenuItem key={status} value={status}>
                            {PROJECT_STATUS_LABELS[status]}{' '}
                          </MenuItem>
                        ))}
                      </Field>
                    </Col>
                  </Row>

                  <Row>
                    <Col size={6}>
                      <Field
                        name="serviceType"
                        component={TextFieldAdapter}
                        validate={required}
                        select
                        label="Service Type"
                        margin="normal"
                        fullWidth
                        data-cy="project-service-type-select"
                      >
                        {PROJECT_SERVICE_TYPES.map(type => (
                          <MenuItem key={type} value={type}>
                            {PROJECT_SERVICE_TYPE_LABELS[type]}
                          </MenuItem>
                        ))}
                      </Field>
                    </Col>
                    <Col size={6}>
                      <Field
                        name="phase"
                        component={TextFieldAdapter}
                        validate={required}
                        select
                        label="Phase"
                        margin="normal"
                        fullWidth
                        disabled={!values.serviceType}
                        data-cy="project-phase-select"
                      >
                        {values.serviceType ? (
                          PROJECT_PHASES_BY_SERVICE_TYPE[
                            values.serviceType
                          ].map(phase => (
                            <MenuItem key={phase} value={phase}>
                              {PROJECT_PHASE_LABELS[phase]}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>''</MenuItem>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col size={6}>
                      <Field
                        name="businessEntity"
                        component={TextFieldAdapter}
                        validate={required}
                        select
                        label="Entity"
                        margin="normal"
                        fullWidth
                        data-cy="project-entity"
                      >
                        {PROJECT_ENTITIES.map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Field>
                    </Col>
                    <Col size={6}>
                      <Field
                        name="code"
                        component={TextFieldAdapter}
                        margin="normal"
                        label="Code (optional)"
                        fullWidth
                        inputProps={{
                          'data-cy': 'project-code-input'
                        }}
                      />
                    </Col>
                  </Row>
                </DialogContent>

                <DialogActions>
                  <Button onClick={onClose}>Cancel</Button>

                  <ProgressButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={submitting}
                    data-cy="project-add-button"
                  >
                    Add
                  </ProgressButton>
                </DialogActions>
              </form>
            )}
          />
        )}
      </Mutation>
    </ErrorBoundary>
  </Dialog>
);

const createProjectMutation = gql`
  mutation createProject(
    $name: String!
    $serviceType: ProjectServiceType!
    $status: ProjectStatus!
    $phase: ProjectPhase!
    $businessEntity: BusinessEntity
    $code: String
  ) {
    createProject(
      name: $name
      serviceType: $serviceType
      status: $status
      phase: $phase
      businessEntity: $businessEntity
      code: $code
    ) {
      id
      name
      startsAt
      endsAt
      status
      phase
      opportunity
      businessEntity
      client {
        name
      }
      partnershipManager {
        id
        firstName
        lastName
      }
    }
  }
`;

export default withRouter(NewProjectDialog);
