import toDate from 'date-fns/toDate';
import format from 'date-fns/format';

export const parseNumberInt = value => (value ? parseInt(value, 10) : null);

export const parseNumberFloat = value => (value ? parseFloat(value) : null);

export const parseDatePicker = value => value && format(value, 'yyyy-MM-dd');

export const parseClearableDatePicker = value => value ? format(value, 'yyyy-MM-dd') : null;

export const formatDatePicker = value => (value ? toDate(value) : null);
