import React, { Component, Suspense, lazy } from 'react';
import { shape, bool, object } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Network from 'react-network';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '@mvpf/platform-shared/components/Loading';
import Analytics from '@mvpf/platform-shared/components/Analytics';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';
import GlobalSearch from './components/GlobalSearch';
import Notifications from './components/Notifications';
import NewEliteDialog from './components/NewEliteDialog';
import NewProjectDialog from './components/NewProjectDialog';
import NewCompanyDialog from './components/NewCompanyDialog';
import NewAdminDialog from './components/NewAdminDialog';
import Logout from './screens/Logout';
import NotFound from './screens/NotFound';

const Login = lazy(() => import('./screens/Login'));
const SetPassword = lazy(() => import('./screens/SetPassword'));
const Elites = lazy(() => import('./screens/Elites'));
const Elite = lazy(() => import('./screens/Elite'));
const Projects = lazy(() => import('./screens/Projects'));
const Project = lazy(() => import('./screens/Project'));
const ProjectMember = lazy(() => import('./screens/ProjectMember'));
const NewJob = lazy(() => import('./screens/NewJob'));
const Job = lazy(() => import('./screens/Job'));
const Jobs = lazy(() => import('./screens/Jobs'));
const Companies = lazy(() => import('./screens/Companies'));
const Company = lazy(() => import('./screens/Company'));
const Settings = lazy(() => import('./screens/Settings'));

class App extends Component {
  state = {
    notificationsOpen: false,
    globalSearchOpen: false,
    newEliteDialogOpen: false,
    newProjectDialogOpen: false,
    newCompanyDialogOpen: false,
    newAdminDialogOpen: false
  };

  handleNotificationsClick = () => {
    this.setState({ notificationsOpen: true });
  };

  handleNotificationsClose = () => {
    this.setState({ notificationsOpen: false });
  };

  handleGlobalSearchClick = () => {
    this.setState({ globalSearchOpen: true });
  };

  handleGlobalSearchClose = () => {
    this.setState({ globalSearchOpen: false });
  };

  handleNewEliteClick = () => {
    this.setState({ newEliteDialogOpen: true });
  };

  handleNewEliteDialogClose = () => {
    this.setState({ newEliteDialogOpen: false });
  };

  handleNewProjectClick = () => {
    this.setState({ newProjectDialogOpen: true });
  };

  handleNewProjectDialogClose = () => {
    this.setState({ newProjectDialogOpen: false });
  };

  handleNewCompanyClick = () => {
    this.setState({ newCompanyDialogOpen: true });
  };

  handleNewCompanyDialogClose = () => {
    this.setState({ newCompanyDialogOpen: false });
  };

  handleNewAdminClick = () => {
    this.setState({ newAdminDialogOpen: true });
  };

  handleNewAdminDialogClose = () => {
    this.setState({ newAdminDialogOpen: false });
  };

  render() {
    const {
      data: { loading, error, user }
    } = this.props;
    const {
      notificationsOpen,
      globalSearchOpen,
      newEliteDialogOpen,
      newProjectDialogOpen,
      newCompanyDialogOpen,
      newAdminDialogOpen
    } = this.state;

    if (loading) {
      return <Loading fullPage />;
    }

    if (error) {
      throw new Error(error);
    }

    return (
      <div>
        <Helmet
          defaultTitle="MVP Factory Admin"
          titleTemplate="%s - MVP Factory Admin"
        />

        {user && (
          <div>
            <Header
              onGlobalSearchClick={this.handleGlobalSearchClick}
              onNotificationsClick={this.handleNotificationsClick}
              onNewEliteClick={this.handleNewEliteClick}
              onNewProjectClick={this.handleNewProjectClick}
              onNewCompanyClick={this.handleNewCompanyClick}
              onNewAdminClick={this.handleNewAdminClick}
            />

            <Notifications
              open={notificationsOpen}
              onClose={this.handleNotificationsClose}
            />

            <GlobalSearch
              open={globalSearchOpen}
              onClose={this.handleGlobalSearchClose}
            />

            {newEliteDialogOpen && (
              <NewEliteDialog onClose={this.handleNewEliteDialogClose} />
            )}

            {newProjectDialogOpen && (
              <NewProjectDialog
                currentUser={user}
                onClose={this.handleNewProjectDialogClose}
              />
            )}

            {newCompanyDialogOpen && (
              <NewCompanyDialog
                currentUser={user}
                onClose={this.handleNewCompanyDialogClose}
              />
            )}

            {newAdminDialogOpen && (
              <NewAdminDialog onClose={this.handleNewAdminDialogClose} />
            )}
          </div>
        )}

        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/elites" />} />

            <Route
              path="/login"
              render={props => <Login {...props} currentUser={user} />}
            />

            <Route path="/logout" component={Logout} />

            <Route path="/set-password/:token" component={SetPassword} />

            <PrivateRoute
              exact
              path="/elites"
              currentUser={user}
              component={Elites}
            />

            <PrivateRoute
              path="/elites/:id"
              currentUser={user}
              component={Elite}
            />

            <PrivateRoute
              exact
              path="/projects"
              currentUser={user}
              component={Projects}
            />

            <PrivateRoute
              path="/projects/:id"
              currentUser={user}
              component={Project}
              onNewCompanyClick={this.handleNewCompanyClick}
            />

            <PrivateRoute
              path="/project-members/:id"
              currentUser={user}
              component={ProjectMember}
            />

            <PrivateRoute
              path="/new-job/:projectId"
              currentUser={user}
              component={NewJob}
            />

            <PrivateRoute path="/jobs/:id" currentUser={user} component={Job} />

            <PrivateRoute
              exact
              path="/jobs"
              currentUser={user}
              component={Jobs}
            />

            <PrivateRoute
              exact
              path="/companies"
              currentUser={user}
              component={Companies}
            />

            <PrivateRoute
              path="/companies/:id"
              currentUser={user}
              component={Company}
            />

            <PrivateRoute
              path="/settings"
              currentUser={user}
              component={Settings}
            />

            <Route component={NotFound} />
          </Switch>
        </Suspense>

        <ToastContainer />

        <Network
          onChange={({ online }) => {
            if (online) {
              toast.dismiss(this.networkToastId);
            } else {
              this.networkToastId = toast.warn('No Internet Connection !', {
                autoClose: false
              });
            }
          }}
        />

        {process.env.REACT_APP_ENABLE_ANALYTICS === 'true' && <Analytics />}
      </div>
    );
  }
}

App.propTypes = {
  data: shape({
    loading: bool,
    error: object,
    user: object
  }).isRequired
};

export const currentUserQuery = gql`
  query currentUser {
    user {
      id
      isAdmin
    }
  }
`;

export default graphql(currentUserQuery, {
  options: { fetchPolicy: 'network-only' }
})(App);
