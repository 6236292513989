import React, { Component } from 'react';
import Downshift from 'downshift';
import { css } from 'glamor';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import {
  AutocompleteWrapper,
  AutocompleteList,
  AutocompleteListInfo
} from './AutocompleteElements';
import GooglePlacesAutocompleteService from './GooglePlacesAutocompleteService';
import uniqBy from 'lodash/uniqBy';
import countries from '../constants/countries';
import Z_INDEX from '../constants/z-index';

class CityAutocomplete extends Component {
  popperNode = React.createRef();

  render() {
    const { inputProps, InputLabelProps, country, ...rest } = this.props;

    return (
      <Downshift {...rest}>
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <AutocompleteWrapper
              {...getRootProps({
                refKey: 'innerRef',
                fullWidth: inputProps && inputProps.fullWidth
              })}
            >
              <TextField
                {...getInputProps({
                  inputRef: this.popperNode,
                  label: 'City',
                  InputLabelProps: InputLabelProps,
                  ...inputProps
                })}
              />

              <Popper
                open={isOpen && !!inputValue}
                anchorEl={this.popperNode.current}
                className={`${css({ zIndex: Z_INDEX.autocomplete })}`}
              >
                <div
                  {...(isOpen
                    ? getMenuProps({}, { suppressRefError: true })
                    : {})}
                >
                  <AutocompleteList
                    width={
                      this.popperNode.current
                        ? this.popperNode.current.clientWidth
                        : null
                    }
                  >
                    <GooglePlacesAutocompleteService
                      input={inputValue}
                      types={['(cities)']}
                      componentRestrictions={
                        country && {
                          country: countries.find(c => c.name === country).code
                        }
                      }
                    >
                      {({ loading, results }) => {
                        if (loading) {
                          return (
                            <AutocompleteListInfo>
                              Loading...
                            </AutocompleteListInfo>
                          );
                        }

                        if (!results) {
                          return (
                            <AutocompleteListInfo>
                              No cities found.
                            </AutocompleteListInfo>
                          );
                        }

                        const uniqResults = uniqBy(
                          results,
                          'structured_formatting.main_text'
                        );

                        return uniqResults.map((item, index) => (
                          <MenuItem
                            {...getItemProps({
                              key: item.id,
                              index,
                              item: item.structured_formatting.main_text,
                              selected: highlightedIndex === index,
                              style: {
                                fontWeight:
                                  selectedItem === item.structured_formatting.main_text
                                    ? 'bold'
                                    : 'normal'
                              }
                            })}
                          >
                            {item.structured_formatting.main_text}
                          </MenuItem>
                        ));
                      }}
                    </GooglePlacesAutocompleteService>
                  </AutocompleteList>
                </div>
              </Popper>
            </AutocompleteWrapper>
          );
        }}
      </Downshift>
    );
  }
}

export default CityAutocomplete;
