import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';
import { USER_POSITION_LABELS } from '@mvpf/platform-shared/constants/user';

const CheckAssignmentNotification = ({ notification, ...otherProps }) => (
  <Notification
    title="Assignment submitted"
    description={
      <Fragment>
        Please review assignment of{' '}
        <Link to={`/elites/${notification.application.user.id}`}>
          {notification.application.user.fullName}
        </Link>{' '}
        - {USER_POSITION_LABELS[notification.application.user.primaryPosition]}
      </Fragment>
    }
    notification={notification}
    {...otherProps}
  />
);

export default CheckAssignmentNotification;
