import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const RadioGroupAdapter = ({
  input: { name, onChange, value },
  meta,
  label,
  margin,
  fullWidth,
  disabled,
  ...rest
}) => (
  <FormControl
    component="fieldset"
    margin={margin}
    fullWidth={fullWidth}
    disabled={disabled}
    error={meta.touched && meta.error ? true : false}
  >
    {label && <FormLabel component="legend">{label}</FormLabel>}

    <RadioGroup
      name={name}
      value={value}
      onChange={event => onChange(event.target.value)}
      {...rest}
    />

    {meta.touched &&
      meta.error && <FormHelperText>{meta.error}</FormHelperText>}
  </FormControl>
);

export default RadioGroupAdapter;
