import React from 'react';
import { Textarea } from 'glamorous';
import { FONT_FAMILY } from '../constants/theme';

const TextAreaAdapter = ({ input, meta, ...rest }) => (
  <Textarea
    {...rest}
    {...input}
    helperText={meta.touched ? meta.error : undefined}
    value={input.value}
    fontFamily={FONT_FAMILY}
  />
);

export default TextAreaAdapter;
