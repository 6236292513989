import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';

const AdminFillTimesheetNotification = ({ notification, ...otherProps }) => (
  <Notification
    title={
      <Fragment>
        Fill this week's timesheets for{' '}
        <Link to={`/projects/${notification.project.id}/time`}>
          {notification.project.name}
        </Link>
      </Fragment>
    }
    notification={notification}
    {...otherProps}
  />
);

export default AdminFillTimesheetNotification;
