import React from 'react';
import { Form } from 'glamorous';

const FormInsideDialogFix = ({ children, ...otherProps }) => (
  <Form display="flex" flexDirection="column" {...otherProps}>
    <span />
    {children}
  </Form>
);

export default FormInsideDialogFix;
