export const generateNotificationQueryFilter = filter => {
  let queryFilter = { AND: [] };

  if (filter === 'unread') {
    queryFilter.AND.push({
      isRead: false
    });
  }

  if (filter === 'read') {
    queryFilter.AND.push({
      isRead: true
    });
  }

  if (queryFilter.AND.length === 0) {
    queryFilter = {};
  }

  return queryFilter;
};
