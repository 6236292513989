import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { css } from 'glamor';
import { A, Div } from 'glamorous';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VSpace from '@mvpf/platform-shared/components/VSpace';
import ProgressButton from '@mvpf/platform-shared/components/ProgressButton';
import RadioGroupAdapter from '@mvpf/platform-shared/components/RadioGroupAdapter';
import TextFieldAdapter from '@mvpf/platform-shared/components/TextFieldAdapter';
import CheckboxAdapter from '@mvpf/platform-shared/components/CheckboxAdapter';
import ErrorMessage from '@mvpf/platform-shared/components/ErrorMessage';
import ErrorBoundary from '@mvpf/platform-shared/components/ErrorBoundary';
import InfoTooltip from '@mvpf/platform-shared/components/InfoTooltip';
import { Row, Col } from '@mvpf/platform-shared/components/Grid';
import TextAreaAdapter from '@mvpf/platform-shared/components/TextAreaAdapter';
import { WhenFieldUpdates } from '@mvpf/platform-shared/components/FinalFormHelpers';
import CityAutocompleteAdapter from '@mvpf/platform-shared/components/CityAutocompleteAdapter';
import CountryAutocompleteAdapter from '@mvpf/platform-shared/components/CountryAutocompleteAdapter';
import SkillsAutocompleteAdapter from '@mvpf/platform-shared/components/SkillsAutocompleteAdapter';
import LanguagesAutocompleteAdaptor from '@mvpf/platform-shared/components/LanguagesAutocompleteAdaptor';
import ProjectsAutocompleteAdaptor from '@mvpf/platform-shared/components/ProjectsAutocompleteAdaptor';
import CurrencyNumberTextFieldAdapter from '@mvpf/platform-shared/components/CurrencyNumberTextFieldAdapter';
import {
  USER_STATUSES,
  USER_STATUS_LABELS,
  USER_POSITIONS,
  USER_POSITION_LABELS,
} from '@mvpf/platform-shared/constants/user';
import { parseNumberFloat } from '@mvpf/platform-shared/utils/form';
import {
  required,
  mustBeEmail,
  minValue,
  requiredArray,
  mustBeLink,
} from '@mvpf/platform-shared/utils/validation';
import theme from '@mvpf/platform-shared/constants/theme';

const ELITE_STATUSES = [
  USER_STATUSES.SOURCED,
  USER_STATUSES.APPLIED,
  USER_STATUSES.SCHEDULE_1ST,
  USER_STATUSES.PERSONAL_INTERVIEW,
  USER_STATUSES.DECIDE_IF_SEND_ASSIGNMENT,
  USER_STATUSES.SCHEDULE_2ND,
  USER_STATUSES.TECH_INTERVIEW,
  USER_STATUSES.DECISION,
  USER_STATUSES.LEGAL_ONBOARDING,
];

const TECH_INTERVIEW_TYPES = {
  DEVELOPER: 'Developer',
  DESIGNER: 'Designer',
  PRODUCT_MANAGER: 'Product Manager',
  DEVELOPER_NETWORK: 'Developer - Network'
};

const MVPF_FRAME_AGREEMENT = 'MVPF_FRAME_AGREEMENT';
const GTS_FRAME_AGREEMENT = 'GTS_FRAME_AGREEMENT';

const NewEliteDialog = ({ onClose, history }) => {
  const [missingFields, setMissingFields] = useState([]);

  return (
    <Dialog disableBackdropClick open onClose={onClose} fullWidth>
      <ErrorBoundary>
        <Mutation mutation={setApplicationCalendlyTechInterviewMutation}>
          {setApplicationCalendlyTechInterview => (
            <Mutation mutation={addEliteAsProspectMutation}>
              {addProspectToJobs => (
                <Mutation mutation={createUserWithStatus}>
                  {createUserStatusRequiredFields => (
                    <Mutation mutation={createUserMutation}>
                      {createUser => (
                        <Form
                          onSubmit={async values => {
                            try {
                              const docs = [];
                              const variables = {
                                input: {
                                  isActivated: values.isActivated,
                                  email: values.email,
                                  firstName: values.firstName,
                                  lastName: values.lastName,
                                  primaryPosition: values.primaryPosition,
                                  dayRate: values.dayRate,
                                  country: values.country,
                                  city: values.city,
                                  status: values.status,
                                  skills: values.skills.map(item => ({ skillId: item.id, isImportant: item.isImportant })),
                                  languages: values.languages.map(lang => ({ name: lang, proficiency: 'FULL' })),
                                  socialMediaLink: values.socialMediaLink,
                                  welcomeMessage: values.welcomeMessage,
                                  cv: values.cv,
                                  techInterviewOn: values.techInterviewOn,
                                }
                              }

                              if (values.mvpfFrameAgreement) {
                                docs.push(MVPF_FRAME_AGREEMENT);
                              }
                              if (values.gtsFrameAgreement) {
                                docs.push(GTS_FRAME_AGREEMENT);
                              }

                              if (values.status === USER_STATUSES.LEGAL_ONBOARDING) {
                                variables.input.shouldSignDocuments = docs;
                              }

                              const { data } = await createUserStatusRequiredFields({ variables });
                              const fields = data.createUserStatusRequiredFields
                                .filter(item => item.isRequired && !item.isFilled)
                                .map(item => item.label);

                              setMissingFields(fields);

                              if (fields.length) {
                                return { [FORM_ERROR]: `The status you selected requires also the following fields: ${fields.join(' ,')}` };
                              } else {
                                const response = await createUser({
                                  variables,
                                });

                                if (values.projects.length) {
                                  await addProspectToJobs({
                                    variables: {
                                      jobIds: values.projects.map(job => job.id),
                                      userId: response.data.createUser.id
                                    }
                                  });
                                }

                                if (values.techInterviewOn === 'CALENDLY') {
                                  await setApplicationCalendlyTechInterview({
                                    variables: {
                                      applicationId: response.data.createUser.application.id,
                                      type: values.type
                                    }
                                  });
                                }

                                setMissingFields([]);

                                if (!values.addAnother) {
                                  onClose();
                                  history.push(`/elites/${response.data.createUser.id}`);
                                } else {
                                  toast.success('User has been added.');
                                }
                              }
                            } catch (error) {
                              return { [FORM_ERROR]: error.message };
                            }
                          }}
                          initialValues={{
                            isActivated: false,
                            skills: [],
                            languages: [],
                            projects: [],
                            mvpfFrameAgreement: true,
                            gtsFrameAgreement: true,
                          }}
                          render={({ handleSubmit, reset, submitting, values, submitError }) => (
                            <form
                              onSubmit={event => {
                                const result = handleSubmit(event);
                                if (result) {
                                  result.then(reset);
                                }
                              }}
                              data-cy="new-elite-form"
                            >
                              <DialogTitle>New Elite</DialogTitle>

                              <DialogContent>
                                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                                <Row>
                                  <Col size={6}>
                                    <Field
                                      name="firstName"
                                      component={TextFieldAdapter}
                                      validate={required}
                                      label="First Name"
                                      margin="normal"
                                      fullWidth
                                      inputProps={{
                                        'data-cy': 'elite-first-name-input'
                                      }}
                                    />
                                  </Col>
                                  <Col size={6}>
                                    <Field
                                      name="lastName"
                                      component={TextFieldAdapter}
                                      validate={required}
                                      label="Last Name"
                                      margin="normal"
                                      fullWidth
                                      inputProps={{
                                        'data-cy': 'elite-last-name-input'
                                      }}
                                    />
                                  </Col>
                                </Row>

                                <Field
                                  name="primaryPosition"
                                  component={TextFieldAdapter}
                                  validate={required}
                                  select
                                  label="Primary Position"
                                  margin="normal"
                                  fullWidth
                                  inputProps={{
                                    'data-cy': 'elite-primary-position'
                                  }}
                                >
                                  {USER_POSITIONS.sort().map(position => (
                                    <MenuItem key={position} value={position}>
                                      {USER_POSITION_LABELS[position]}
                                    </MenuItem>
                                  ))}
                                </Field>
                                <Div fontSize="1rem" marginTop="1rem">
                                  How to correctly choose the onboarding status for your current situation, you can check&nbsp;
                                  <A
                                    href="https://mvpfactory.atlassian.net/wiki/spaces/MVPF/pages/1662222347/How+to+Onboarding-flow"
                                    target="_blank"
                                  >
                                    here.
                                  </A>
                                </Div>
                                <Field
                                  name="status"
                                  component={TextFieldAdapter}
                                  validate={required}
                                  select
                                  label="Onboarding Status"
                                  margin="normal"
                                  fullWidth
                                  inputProps={{
                                    'data-cy': 'elite-status'
                                  }}
                                >
                                  {ELITE_STATUSES.map(item => (
                                    <MenuItem key={item} value={item}>
                                      {USER_STATUS_LABELS[item]}
                                    </MenuItem>
                                  ))}
                                </Field>

                                {values.status === USER_STATUSES.LEGAL_ONBOARDING && (
                                  <Div display="flex" flexDirection="column" marginTop={16} marginBottom={8}>
                                    <Div fontSize="1rem">
                                      The Elite should sign:
                                      <InfoTooltip text={
                                        <>
                                          If you’re unsure how the contractual process works, you can read about it
                                          &nbsp;
                                          <A target="_blank" href="https://mvpfactory.atlassian.net/wiki/spaces/MVPF/pages/1668775961/Legal+Docs+for+Freelancers">
                                            here.
                                          </A>
                                        </>
                                      } />
                                    </Div>
                                    <FormControlLabel
                                      control={
                                        <Field
                                          name="mvpfFrameAgreement"
                                          component={CheckboxAdapter}
                                          type="checkbox"
                                          color="primary"
                                        />
                                      }
                                      label="MVPF Frame Agreement"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Field
                                          name="gtsFrameAgreement"
                                          component={CheckboxAdapter}
                                          type="checkbox"
                                          color="primary"
                                        />
                                      }
                                      label="GTS Frame Agreement"
                                    />
                                  </Div>
                                )}

                                <Field
                                  name="socialMediaLink"
                                  component={TextFieldAdapter}
                                  validate={(value, allValues) => {
                                    if (!value && allValues.cv) {
                                      return undefined;
                                    }

                                    if (!value && !allValues.cv) {
                                      return 'Required';
                                    }

                                    if (value) {
                                      return mustBeLink(value);
                                    }
                                  }}
                                  label="Social media link"
                                  margin="normal"
                                  fullWidth
                                  inputProps={{
                                    'data-cy': 'elite-social-media-link'
                                  }}
                                />

                                <Field
                                  name="cv"
                                  validate={(value, allValues) => {
                                    if (!value && allValues.socialMediaLink) {
                                      return undefined;
                                    }

                                    if (!value && !allValues.socialMediaLink) {
                                      return 'Required';
                                    }

                                    if (value.size > 1024 * 1024 * 5) {
                                      return 'File size should be less than 5 MB';
                                    }
                                  }}
                                  render={({ input: { value, onChange, onBlur }, meta }) => (
                                    <div
                                      className={css({
                                        padding: 10,
                                        borderRadius: 3,
                                        backgroundColor: '#fff',
                                        border: '1px solid #eaebf0',
                                      })}
                                    >
                                      <div>
                                        <div className={css({ fontSize: 16 })} >Personal Resume</div>

                                        <input
                                          id="upload-resume"
                                          type="file"
                                          accept="application/pdf"
                                          className={css({
                                            display: 'none'
                                          })}
                                          onChange={({
                                            target: {
                                              validity,
                                              files: [file]
                                            }
                                          }) => {
                                            if (validity.valid && file) {
                                              onChange(file);
                                              onBlur();
                                            }
                                          }}
                                        />
                                        <label htmlFor="upload-resume">
                                          <Button
                                            color="primary"
                                            component="span"
                                            css={{
                                              textTransform: 'none',
                                              textDecoration: 'underline'
                                            }}
                                          >
                                            {value ? 'update' : 'upload'}
                                          </Button>
                                        </label>
                                      </div>

                                      {value && (
                                        <div
                                          className={css({
                                            color: theme.colors.rollingStone,
                                            marginTop: 10
                                          })}
                                        >
                                          {value.name}
                                        </div>
                                      )}

                                      {meta.error && meta.touched && (
                                        <ErrorMessage css={{ marginTop: 10 }}>{meta.error}</ErrorMessage>
                                      )}
                                    </div>
                                  )}
                                />

                                <Field
                                  name="skills"
                                  component={SkillsAutocompleteAdapter}
                                  validate={requiredArray}
                                  label="Specialities"
                                  placeholder="Skills (only add the specialities)"
                                  canToggleImportance
                                  inputProps={{
                                    fullWidth: true,
                                    margin: 'normal',
                                    'data-cy': 'elite-skills',
                                    InputProps: {
                                      endAdornment: (
                                        <InfoTooltip text={`Specialities are all the skills that the person is
                                        particulary good at and that you think they could contribute with at MVPF.`} />
                                      )
                                    }
                                  }}
                                />

                                <Field
                                  name="dayRate"
                                  component={CurrencyNumberTextFieldAdapter}
                                  validate={minValue(1)}
                                  parse={parseNumberFloat}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        &euro;
                                      </InputAdornment>
                                    )
                                  }}
                                  label="Day Rate (per PD) (optional)"
                                  margin="normal"
                                  fullWidth
                                />

                                <Row>
                                  <Col size={6}>
                                    <Field
                                      name="country"
                                      label="Country"
                                      component={CountryAutocompleteAdapter}
                                      validate={required}
                                      inputProps={{
                                        autoComplete: "none",
                                        margin: 'normal',
                                        fullWidth: true
                                      }}
                                    />
                                  </Col>

                                  <WhenFieldUpdates
                                    field="country"
                                    set="city"
                                    to={null}
                                  />

                                  {values.country && (
                                    <Col size={6}>
                                      <Field
                                        name="city"
                                        label="City"
                                        component={CityAutocompleteAdapter}
                                        validate={required}
                                        country={values.country}
                                        inputProps={{
                                          autoComplete: "none",
                                          margin: 'normal',
                                          fullWidth: true
                                        }}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <Div fontWeight="bold" fontSize={16}>
                                  What languages is the elite fluent in?
                                </Div>

                                <Field
                                  name="languages"
                                  component={LanguagesAutocompleteAdaptor}
                                  placeholder="Start typing a language"
                                  inputProps={{
                                    autoComplete: "none",
                                    margin: 'normal',
                                    fullWidth: true,
                                  }}
                                />

                                <Div fontWeight="bold" fontSize={16}>
                                  Do you want to link the elite to a job? (optional)
                                </Div>

                                <Field
                                  name="projects"
                                  component={ProjectsAutocompleteAdaptor}
                                  placeholder="Start typing job title or project name"
                                  inputProps={{
                                    autoComplete: "none",
                                    margin: 'normal',
                                    fullWidth: true,
                                  }}
                                />

                                {values.status === USER_STATUSES.SCHEDULE_2ND && (
                                  <>
                                    <Div fontWeight="bold" fontSize={16}>
                                      How do you want to schedule the technical interview?
                                    </Div>

                                    <Field
                                      name="techInterviewOn"
                                      component={RadioGroupAdapter}
                                      validate={required}
                                      fullWidth
                                    >
                                      <FormControlLabel
                                        key="CALENDLY"
                                        value="CALENDLY"
                                        control={<Radio color="primary" />}
                                        label="Calendly"
                                      />
                                      <FormControlLabel
                                        key="OFF_PLATFORM"
                                        value="OFF_PLATFORM"
                                        control={<Radio color="primary" />}
                                        label="Off-Platform"
                                      />
                                    </Field>

                                    {values.techInterviewOn === 'CALENDLY' && (
                                      <Field
                                        name="type"
                                        component={TextFieldAdapter}
                                        validate={required}
                                        select
                                        label="Pick Calendar"
                                        margin="normal"
                                        fullWidth
                                      >
                                        {Object.keys(TECH_INTERVIEW_TYPES).map(
                                          key => (
                                            <MenuItem key={key} value={key}>
                                              {TECH_INTERVIEW_TYPES[key]}
                                            </MenuItem>
                                          )
                                        )}
                                      </Field>
                                    )}
                                  </>
                                )}

                                {(values.isActivated || values.status === USER_STATUSES.LEGAL_ONBOARDING) && (
                                  <Field
                                    name="email"
                                    component={TextFieldAdapter}
                                    validate={(value, allValues) => {
                                      if (allValues.isActivated
                                        || values.status === USER_STATUSES.LEGAL_ONBOARDING
                                        || missingFields.includes('EMAIL')) {
                                        return value
                                          ? mustBeEmail(value)
                                          : required(value)
                                      }
                                    }}
                                    type="email"
                                    label="Email"
                                    margin="normal"
                                    fullWidth
                                    inputProps={{
                                      'data-cy': 'elite-email-input'
                                    }}
                                  />
                                )}

                                {values.isActivated && (
                                  <>
                                    <VSpace size={10} />
                                    <Field
                                      name="welcomeMessage"
                                      component={TextAreaAdapter}
                                      placeholder="Add a custom message for the welcome email... (optional)"
                                      cols={60}
                                      rows={5}
                                      className={`${css({
                                        outline: 'none',
                                        resize: 'none',
                                        width: '100%',
                                        fontSize: 16,
                                        padding: 5,
                                      })}`}
                                    />
                                  </>
                                )}

                                <FormControlLabel
                                  control={
                                    <Field
                                      name="isActivated"
                                      component={CheckboxAdapter}
                                      type="checkbox"
                                      color="primary"
                                    />
                                  }
                                  label="Activate user account"
                                />
                                <InfoTooltip text={`Activating the account will give users access to their platform account.
                                  This will trigger a welcome email asking to set a password to complete the sign up.`} />
                              </DialogContent>

                              <DialogActions>
                                <FormControlLabel
                                  control={
                                    <Field
                                      name="addAnother"
                                      component={CheckboxAdapter}
                                      type="checkbox"
                                      color="primary"
                                    />
                                  }
                                  label="Add another"
                                />

                                <Button onClick={onClose}>Cancel</Button>

                                <ProgressButton
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  loading={submitting}
                                  data-cy="elite-add-button"
                                >
                                  Add
                                </ProgressButton>
                              </DialogActions>
                            </form>
                          )}
                        />
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      </ErrorBoundary>
    </Dialog>
  )
};

const createUserMutation = gql`
  mutation createUser(
    $input: CreateUserInput!
  ) {
    createUser(
      input: $input
    ) {
      id
      application {
        id
      }
    }
  }
`;

const createUserWithStatus = gql`
  mutation createUserStatusRequiredFields($input: CreateUserInput!) {
    createUserStatusRequiredFields(input: $input) {
      label
      isRequired
      isFilled
    }
  }
`;

const addEliteAsProspectMutation = gql`
  mutation addEliteAsProspect($jobIds: [GUID!], $userId: GUID!) {
    addProspectToJobs(jobIds: $jobIds, userId: $userId) {
      id
    }
  }
`;

const setApplicationCalendlyTechInterviewMutation = gql`
  mutation setApplicationCalendlyTechInterview(
    $applicationId: ID!
    $type: TechInterviewType!
  ) {
    setApplicationCalendlyTechInterview(id: $applicationId, type: $type) {
      id
      calendlyTechInterview {
        isSent
      }
    }
  }
`;

export default withRouter(NewEliteDialog);
