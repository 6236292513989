import React from 'react';
import { Div } from 'glamorous';
import Loading from '@mvpf/platform-shared/components/Loading';
import ErrorBoundary from '@mvpf/platform-shared/components/ErrorBoundary';
import Button from '@material-ui/core/Button';
import CheckAssignmentNotification from './types/CheckAssignmentNotification';
import NewApplicationNotification from './types/NewApplicationNotification';
import ProjectHealthCollectionCompletedNotification from './types/ProjectHealthCollectionCompletedNotification';
import ProjectHealthReportingNotification from './types/ProjectHealthReportingNotification';
import ProjectPhaseChangedNotification from './types/ProjectPhaseChangedNotification';
import ProjectStatusChangedNotification from './types/ProjectStatusChangedNotification';
import TechInterviewAcceptedNotification from './types/TechInterviewAcceptedNotification';
import TechInterviewCompletedNotification from './types/TechInterviewCompletedNotification';
import AdminApproveTimesheetsNotification from './types/AdminApproveTimesheetsNotification';
import AdminFillTimesheetNotification from './types/AdminFillTimesheetNotification';
import { COLORS } from '@mvpf/platform-shared/constants/theme';

const NOTIFICATION_TYPES = {
  CheckAssignmentNotification,
  NewApplicationNotification,
  ProjectHealthCollectionCompletedNotification,
  ProjectHealthReportingNotification,
  ProjectPhaseChangedNotification,
  ProjectStatusChangedNotification,
  TechInterviewAcceptedNotification,
  TechInterviewCompletedNotification,
  AdminApproveTimesheetsNotification,
  AdminFillTimesheetNotification
};

const NotificationList = ({
  loading,
  loadingMore,
  notifications,
  hasMoreNotifications,
  onShowMore,
  onMarkRead,
  onMarkUnread
}) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {notifications.length > 0 ? (
        <Div borderTop={`1px solid ${COLORS.alto}`}>
          {notifications.map(notification => {
            const NotificationElement =
              NOTIFICATION_TYPES[notification.__typename];

            return (
              <ErrorBoundary key={notification.id}>
                <NotificationElement
                  notification={notification}
                  onMarkRead={onMarkRead}
                  onMarkUnread={onMarkUnread}
                />
              </ErrorBoundary>
            );
          })}
        </Div>
      ) : (
        !hasMoreNotifications && (
          <Div textAlign="center" fontWeight="bold">
            You're up to date{' '}
            <span role="img" aria-labelledby="jsx-a11y/accessible-emoji">
              🙌🏼
            </span>
          </Div>
        )
      )}

      {loadingMore ? (
        <Loading />
      ) : (
        hasMoreNotifications && (
          <Button fullWidth onClick={onShowMore}>
            Show more
          </Button>
        )
      )}
    </div>
  );
};

export default NotificationList;
