export const required = value =>
  value !== undefined && value !== null ? undefined : 'Required';

export const requiredArray = value =>
  value && value.length > 0 ? undefined : 'Required';

export const requiredRichText = value =>
  !required(value) &&
  value !== '<p></p>' &&
  value !== '<h1></h1>' &&
  value !== '<h2></h2>'
    ? undefined
    : 'Required';

export const mustBeNumber = value =>
  isNaN(value) ? 'Must be a number' : undefined;

export const minValue = min => value =>
  value === undefined || value === null || value >= min
    ? undefined
    : `Should be greater than ${min}`;

export const maxValue = max => value =>
  value === undefined || value === null || value <= max
    ? undefined
    : `Should be less than ${max}`;

export const mustBeEmail = value => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(value) || !value) {
    return undefined;
  } else {
    return 'Must be an email';
  }
};

export const mustBeLink = value => {
  const re = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  if (re.test(value) || !value) {
    return undefined;
  } else {
    return 'Must be a link';
  }
};

export const mustBeTheSame = fieldToMatch => (value, allValues) =>
  value === allValues[fieldToMatch]
    ? undefined
    : `Should be the same with ${fieldToMatch}`;

export const mustBePhone = value => {
  const re = /^\+\d/;

  if (re.test(value) || !value) {
    return undefined;
  } else {
    return 'Must be a phone number';
  }
};

export const minLength = min => value =>
  !value || value.length >= min
    ? undefined
    : `Must be at least ${min} characters`;

export const maxLength = max => value =>
  !value || value.length <= max
    ? undefined
    : `Must be at most ${max} characters`;

export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce(
    (error, validator) => error || validator(value, allValues),
    undefined
  );
