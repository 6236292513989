import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';

const TechInterviewCompletedNotification = ({
  notification,
  ...otherProps
}) => (
  <Notification
    title="Review Scorecard"
    description={
      <Fragment>
        {notification.application.techInterviewer && (
          <Link to={`/elites/${notification.application.techInterviewer.id}`}>
            {notification.application.techInterviewer.fullName}
          </Link>
        )}{' '}
        has submitted the tech interview scorecard for{' '}
        <Link to={`/elites/${notification.application.user.id}`}>
          {notification.application.user.fullName}
        </Link>
        . Please review it and reject or accept the candidate.
      </Fragment>
    }
    notification={notification}
    {...otherProps}
  />
);

export default TechInterviewCompletedNotification;
