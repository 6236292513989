import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';

const TechInterviewAcceptedNotification = ({ notification, ...otherProps }) => (
  <Notification
    title="Tech interview accepted"
    description={
      <Fragment>
        <Link to={`/elites/${notification.application.techInterviewer.id}`}>
          {notification.application.techInterviewer.fullName}
        </Link>{' '}
        has accepted to interview{' '}
        <Link to={`/elites/${notification.application.user.id}`}>
          {notification.application.user.fullName}
        </Link>
        .
      </Fragment>
    }
    notification={notification}
    {...otherProps}
  />
);

export default TechInterviewAcceptedNotification;
