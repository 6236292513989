import React from 'react';
import TextField from '@material-ui/core/TextField';

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...rest}
    {...input}
    helperText={meta.touched ? meta.error : undefined}
    error={meta.error && meta.touched}
  />
);

export default TextFieldAdapter;
