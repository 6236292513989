import glamorous from 'glamorous';
import { SPACING } from '../constants/theme';

export const Row = glamorous.div({
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: -(SPACING / 2),
  marginRight: -(SPACING / 2)
});

export const Col = glamorous.div(({ size }) => ({
  width: `${size / 12 * 100}%`,
  paddingLeft: SPACING / 2,
  paddingRight: SPACING / 2
}));
