import React from 'react';
import Notification from './Notification';

const ProjectHealthCollectionCompletedNotification = ({
  notification,
  ...otherProps
}) => (
  <Notification
    title="Project health round has finished"
    description="The overall project health score results are now available in the project list."
    notification={notification}
    {...otherProps}
  />
);

export default ProjectHealthCollectionCompletedNotification;
