import React from 'react';
import glamorous from 'glamorous';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Clear';
import { COLORS } from '../constants/theme';

export const GreyBox = glamorous.div({
  position: 'relative',
  padding: 10,
  marginBottom: 5,
  border: `1px solid ${COLORS.ghost}`,
  borderRadius: 2,
  backgroundColor: COLORS.catskillWhite
});

const StyledIconButton = glamorous(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0
});

export const GreyBoxDeleteButton = props => (
  <StyledIconButton {...props}>
    <DeleteIcon fontSize="small" color="error" />
  </StyledIconButton>
);

export default GreyBox;
