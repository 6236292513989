import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxAdapter = ({
  input: { checked, name, onChange },
  meta,
  ...rest
}) => (
  <Checkbox {...rest} name={name} onChange={onChange} checked={!!checked} />
);

export default CheckboxAdapter;
