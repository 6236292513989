import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';
import { USER_POSITION_LABELS } from '@mvpf/platform-shared/constants/user';

const NewApplicationNotification = ({ notification, ...otherProps }) => (
  <Notification
    title="New Application"
    description={
      <Fragment>
        <Link to={`/elites/${notification.application.user.id}`}>
          {notification.application.user.fullName}
        </Link>{' '}
        has applied for{' '}
        {USER_POSITION_LABELS[notification.application.user.primaryPosition]}.
        Take a look at the candidate.
      </Fragment>
    }
    notification={notification}
    {...otherProps}
  />
);

export default NewApplicationNotification;
