import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';

const ProjectHealthReportingNotification = ({
  notification,
  ...otherProps
}) => (
  <Notification
    title={
      <Fragment>
        New Project Health Result for{' '}
        <Link to={`/projects/${notification.project.id}/health`}>
          {notification.project.name}
        </Link>
      </Fragment>
    }
    description="You can now review the latest health score for this project."
    notification={notification}
    {...otherProps}
  />
);

export default ProjectHealthReportingNotification;
