import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';
import { PROJECT_STATUS_LABELS } from '@mvpf/platform-shared/constants/project';

const ProjectStatusChangedNotification = ({ notification, ...otherProps }) => (
  <Notification
    title={
      <Fragment>
        <Link to={`/projects/${notification.project.id}`}>
          {notification.project.name}
        </Link>{' '}
        is now in{' '}
        {notification.activity &&
          PROJECT_STATUS_LABELS[notification.activity.vars.statusAfter]}
      </Fragment>
    }
    description={
      <Link to={`/projects/${notification.project.id}`}>
        Visit the project page
      </Link>
    }
    notification={notification}
    {...otherProps}
  />
);

export default ProjectStatusChangedNotification;
