import { COLORS } from './theme';

export const USER_STATUSES = {
  REJECTED: 'REJECTED',
  DELETED: 'DELETED',
  SOURCED: 'SOURCED',
  APPLIED: 'APPLIED',
  SCHEDULE_1ST: 'SCHEDULE_1ST',
  PERSONAL_INTERVIEW: 'PERSONAL_INTERVIEW',
  DECIDE_IF_SEND_ASSIGNMENT: 'DECIDE_IF_SEND_ASSIGNMENT',
  ASSIGNMENT: 'ASSIGNMENT',
  ASSIGNMENT_REVIEW: 'ASSIGNMENT_REVIEW',
  SCHEDULE_2ND: 'SCHEDULE_2ND',
  TECH_INTERVIEW: 'TECH_INTERVIEW',
  DECISION: 'DECISION',
  LEGAL_ONBOARDING: 'LEGAL_ONBOARDING',
  PROFILE_SETUP: 'PROFILE_SETUP',
  READY: 'READY',
}

export const USER_STATUS_LABELS = {
  REJECTED: 'Rejected',
  DELETED: 'Deleted',
  SOURCED: 'Sourced',
  APPLIED: 'Applied',
  SCHEDULE_1ST: 'Schedule 1st',
  PERSONAL_INTERVIEW: 'Personal Interview',
  DECIDE_IF_SEND_ASSIGNMENT: '1st Decision',
  ASSIGNMENT: 'Assignment',
  ASSIGNMENT_REVIEW: 'Assignment Review',
  SCHEDULE_2ND: 'Schedule 2nd',
  TECH_INTERVIEW: 'Technical Interview',
  DECISION: 'Final Decision',
  LEGAL_ONBOARDING: 'Legal Onboarding',
  PROFILE_SETUP: 'Profile Setup',
  READY: 'Ready',
};

export const USER_STATUS_COLORS = {
  REJECTED: COLORS.burntSienna,
  DELETED: COLORS.burntSienna,
  SOURCED: COLORS.yellowOrange,
  APPLIED: COLORS.yellowOrange,
  SCHEDULE_1ST: COLORS.yellowOrange,
  PERSONAL_INTERVIEW: COLORS.yellowOrange,
  DECIDE_IF_SEND_ASSIGNMENT: COLORS.yellowOrange,
  ASSIGNMENT: COLORS.yellowOrange,
  ASSIGNMENT_REVIEW: COLORS.yellowOrange,
  SCHEDULE_2ND: COLORS.yellowOrange,
  TECH_INTERVIEW: COLORS.yellowOrange,
  DECISION: COLORS.yellowOrange,
  LEGAL_ONBOARDING: COLORS.yellowOrange,
  PROFILE_SETUP: COLORS.yellowOrange,
  READY: COLORS.jungleGreen,
};

export const ACCOUNT_STATUS = [
  'INACTIVE',
  'PENDING',
  'ACTIVE',
  'DELETED',
]

export const ACCOUNT_STATUS_LABELS = {
  INACTIVE: 'Inactive',
  PENDING: 'Pending Sign-up',
  ACTIVE: 'Active',
  DELETED: 'Deleted',
}

export const USER_AVAILABILITIES = [
  'FULL_TIME',
  'PART_TIME',
  'LIMITED',
  'UNAVAILABLE',
];

export const USER_AVAILABILITY_LABELS = {
  FULL_TIME: 'Full time',
  PART_TIME: 'Part time',
  LIMITED: 'Limited',
  UNAVAILABLE: 'Unavailable',
};

export const USER_AVAILABILITY_COLORS = {
  FULL_TIME: COLORS.jungleGreen,
  PART_TIME: COLORS.jungleGreen,
  LIMITED: COLORS.jungleGreen,
  UNAVAILABLE: COLORS.burntSienna,
};

export const USER_AVAILABILITY_HOUR_LABELS = {
  FULL_TIME: 'Full time (40h/week)',
  PART_TIME: 'Part time (20h/week)',
  LIMITED: 'Limited (10h/week)',
  UNAVAILABLE: 'Unavailable',
};

export const USER_AVAILABILITY_FACTORS = {
  FULL_TIME: 1,
  PART_TIME: 0.5,
  LIMITED: 0.25,
};

export const USER_STATUSES_APPLICATION_PHASE = [
  'REGISTERED',
  'APPLIED',
  'PERSONAL_INTERVIEW',
  'ASSIGNMENT',
  'TECH_INTERVIEW',
  'APPROVED',
];

export const USER_POSITIONS = [
  'PRODUCT_MANAGEMENT',
  'FULL_STACK_WEB',
  'FRONTEND',
  'BACKEND',
  'IOS',
  'ANDROID',
  'UI_UX_DESIGN',
  'BRANDING',
  'QA_TESTER',
  'QA_ENGINEER',
  'AI_DATA_SCIENCE',
  'COPYWRITING',
  'DEVOPS',
  'NATIVE_APPLICATION',
  'SOFTWARE_ARCHITECTURE',
  'SCRUM_MASTER',
  'USER_RESEARCHER',
  'CODE_REVIEWER',
  'DESIGN_DIRECTOR',
  'PRODUCT_DIRECTOR',
  'ENGAGEMENT_MANAGER',
  'ENGINEERING_MANAGER',
  'PARTNERSHIP_MANAGER',
  'UX_DESIGNER',
  'UI_DESIGNER',
  'AR_ENGINEER',
  'MOTION_DESIGNER',
  'AGILE_COACH',
  'BLOCKCHAIN',
  'WORDPRESS',
  'VOICE_ACTOR',
  'SOURCER',
];

export const USER_POSITION_LABELS = {
  PRODUCT_MANAGEMENT: 'Product Management',
  FULL_STACK_WEB: 'Full Stack Web',
  FRONTEND: 'Frontend',
  BACKEND: 'Backend',
  IOS: 'iOS',
  ANDROID: 'Android',
  UI_UX_DESIGN: 'UI/UX Design',
  BRANDING: 'Branding',
  QA_TESTER: 'QA Tester',
  QA_ENGINEER: 'QA Engineer',
  AI_DATA_SCIENCE: 'AI / Data Science',
  COPYWRITING: 'Copywriting',
  DEVOPS: 'DevOps',
  NATIVE_APPLICATION: 'Native Application',
  SOFTWARE_ARCHITECTURE: 'Software Architecture',
  SCRUM_MASTER: 'SCRUM Master',
  USER_RESEARCHER: 'User Researcher',
  CODE_REVIEWER: 'Code Reviewer',
  DESIGN_DIRECTOR: 'Design Director',
  PRODUCT_DIRECTOR: 'Product Director',
  ENGAGEMENT_MANAGER: 'Engagement Manager',
  ENGINEERING_MANAGER: 'Engineering Manager',
  PARTNERSHIP_MANAGER: 'Partnership Manager',
  UX_DESIGNER: 'UX Designer',
  UI_DESIGNER: 'UI Designer',
  AR_ENGINEER: 'AR Engineer',
  MOTION_DESIGNER: 'Motion Designer',
  AGILE_COACH: 'Agile Coach',
  BLOCKCHAIN: 'Blockchain',
  WORDPRESS: 'WordPress',
  VOICE_ACTOR: 'Voice Actor',
  SOURCER: 'Sourcer',
};

export const APPLICATION_USER_POSITIONS = USER_POSITIONS.filter(
  position =>
    ![
      'CODE_REVIEWER',
      'SOURCER',
      'DESIGN_DIRECTOR',
      'PRODUCT_DIRECTOR',
      'ENGAGEMENT_MANAGER',
      'ENGINEERING_MANAGER',
      'PARTNERSHIP_MANAGER',
    ].includes(position)
);

export const USER_BADGES = [
  'TECH_INTERVIEWER',
  'TECH_LEAD',
];

export const USER_BADGE_LABELS = {
  TECH_INTERVIEWER: 'Tech Interviewer',
  TECH_LEAD: 'Tech Lead',
};

export const USER_PROJECT_POSITION_STATUS_LABELS = {
  INVITED: 'Invited',
  ONBOARDING: 'Onboarding',
  ELITE_AGREEMENT_SIGNED: 'Elite agreement signed',
  ELITE_ACCEPTED: 'Elite accepted',
  ELITE_DECLINED: 'Elite declined',
  DONE: 'Done',
  REMOVED: 'Removed',
};

export const USER_EXPERIENCE_LEVELS = [
  'JUNIOR',
  'MIDDLE',
  'SENIOR',
];

export const USER_EXPERIENCE_LEVEL_LABELS = {
  JUNIOR: 'Junior',
  MIDDLE: 'Middle',
  SENIOR: 'Senior',
};

export const USER_REFERRAL_STATUSES = [
  'SENT',
  'HIRED',
  'APPLIED',
  'APPROVED',
  'REJECTED',
];

export const USER_REFERRAL_STATUS_LABELS = {
  SENT: 'Sent',
  HIRED: 'Hired',
  APPLIED: 'Applied',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export const USER_REFERRAL_STATUS_COLORS = {
  SENT: COLORS.cadetBlue,
  APPLIED: COLORS.yellowOrange,
  APPROVED: COLORS.jungleGreen,
  HIRED: COLORS.jungleGreen,
  REJECTED: COLORS.burntSienna,
};

export const USER_REFERRAL_PAYMENT_STATUSES = [
  'SEND_INVOICE',
  'PAID',
  'REJECTED',
];

export const USER_REFERRAL_PAYMENT_STATUS_LABELS = {
  SEND_INVOICE: 'Send Invoice',
  PAID: 'Paid',
  REJECTED: 'Rejected',
};

export const USER_REFERRAL_PAYMENT_STATUS_COLORS = {
  SEND_INVOICE: COLORS.yellowOrange,
  PAID: COLORS.jungleGreen,
  REJECTED: COLORS.burntSienna,
};
