import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import glamorous, { Div } from 'glamorous';
import { css } from 'glamor';
import { Link, NavLink } from 'react-router-dom';
import ConditionalWrap from 'conditional-wrap';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ErrorBoundary from '@mvpf/platform-shared/components/ErrorBoundary';
import { COLORS } from '@mvpf/platform-shared/constants/theme';
import logo from '@mvpf/platform-shared/assets/logo.svg';
import Container from './Container';
import qs from 'qs';

const Logo = glamorous.img({
  display: 'block',
  width: 59,
  height: 43
});

const HeaderNavLink = glamorous(NavLink)({
  marginLeft: 20,
  color: COLORS.cadetBlue,
  fontWeight: 'bold',
  fontSize: 15,
  textTransform: 'uppercase',
  textDecoration: 'none',
  ':hover': {
    color: COLORS.text
  }
});

const headerNavLinkActiveStyle = {
  color: COLORS.text
};

class Header extends Component {
  state = {
    addMenuAnchorEl: null
  };

  handleClickAdd = event => {
    this.setState({ addMenuAnchorEl: event.currentTarget });
  };

  handleCloseAdd = () => {
    this.setState({ addMenuAnchorEl: null });
  };

  handleNewEliteClick = () => {
    this.handleCloseAdd();
    this.props.onNewEliteClick();
  };

  handleNewProjectClick = () => {
    this.handleCloseAdd();
    this.props.onNewProjectClick();
  };

  handleNewCompanyClick = () => {
    this.handleCloseAdd();
    this.props.onNewCompanyClick();
  };

  handleNewAdminClick = () => {
    this.handleCloseAdd();
    this.props.onNewAdminClick();
  };

  render() {
    const { addMenuAnchorEl } = this.state;
    const { onGlobalSearchClick, onNotificationsClick } = this.props;

    return (
      <Container className="print-hide">
        <Div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={20}
        >
          <Div display="flex" alignItems="center">
            <Link to="/" className={css({ marginRight: 20 })}>
              <Logo src={logo} alt="logo" />
            </Link>

            <HeaderNavLink
              to={{
                pathname: '/projects',
                search: qs.stringify({
                  status: ['ACTIVE', 'LATE_PAYMENT', 'IN_RESOLUTION']
                })
              }}
              activeStyle={headerNavLinkActiveStyle}
            >
              Projects
            </HeaderNavLink>
            <HeaderNavLink to="/elites" activeStyle={headerNavLinkActiveStyle}>
              Elites
            </HeaderNavLink>
            <HeaderNavLink
              to={{
                pathname: '/jobs',
                search: qs.stringify({
                  status: 'OPEN'
                })
              }}
              activeStyle={headerNavLinkActiveStyle}
            >
              Jobs
            </HeaderNavLink>
            <HeaderNavLink
              to="/companies"
              activeStyle={headerNavLinkActiveStyle}
            >
              Companies
            </HeaderNavLink>
          </Div>

          <Div display="flex" alignItems="center" color={COLORS.cadetBlue}>
            <Tooltip title="Global Search">
              <IconButton
                aria-label="Global Seach"
                onClick={onGlobalSearchClick}
                color="inherit"
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Settings">
              <IconButton
                aria-label="Settings"
                component={Link}
                to="/settings"
                color="inherit"
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Add">
              <IconButton
                aria-label="Add"
                aria-owns={addMenuAnchorEl ? 'add-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClickAdd}
                color="inherit"
                data-cy="header-add-menu"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="add-menu"
              anchorEl={addMenuAnchorEl}
              open={Boolean(addMenuAnchorEl)}
              onClose={this.handleCloseAdd}
            >
              <MenuItem
                onClick={this.handleNewEliteClick}
                data-cy="header-add-menu-new-elite"
              >
                New Elite
              </MenuItem>
              <MenuItem
                onClick={this.handleNewProjectClick}
                data-cy="header-add-menu-new-project"
              >
                New Project
              </MenuItem>
              <MenuItem
                onClick={this.handleNewCompanyClick}
                data-cy="header-add-menu-new-company"
              >
                New Company
              </MenuItem>
              <MenuItem onClick={this.handleNewAdminClick}>New Admin</MenuItem>
            </Menu>

            <ErrorBoundary>
              <Query query={headerQuery}>
                {({ error, data }) => {
                  if (error) {
                    throw new Error(error);
                  }

                  return (
                    <Tooltip title="Notifications">
                      <IconButton
                        aria-label="Notifications"
                        onClick={onNotificationsClick}
                        color="inherit"
                      >
                        <ConditionalWrap
                          condition={data.user && data.user.notificationsCount}
                          wrap={children => (
                            <Badge
                              badgeContent={data.user.notificationsCount}
                              color="error"
                            >
                              {children}
                            </Badge>
                          )}
                        >
                          <NotificationsIcon />
                        </ConditionalWrap>
                      </IconButton>
                    </Tooltip>
                  );
                }}
              </Query>
            </ErrorBoundary>

            <Tooltip title="Sign out">
              <IconButton
                aria-label="Sign out"
                component={Link}
                to="/logout"
                color="inherit"
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Div>
        </Div>
      </Container>
    );
  }
}

export const headerQuery = gql`
  query header {
    user {
      id
      notificationsCount(filter: { isRead: false })
    }
  }
`;

export default Header;
