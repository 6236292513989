import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
  removeRefreshToken,
  removeToken
} from '@mvpf/platform-shared/utils/auth';

class Logout extends Component {
  componentDidMount() {
    removeToken();
    removeRefreshToken();
    // TODO improve logout
    this.props.client.resetStore();
    this.props.history.replace('/login');
  }

  render() {
    return null;
  }
}

export default withApollo(Logout);
