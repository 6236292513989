import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { css } from 'glamor';
import glamorous from 'glamorous';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import {
  AutocompleteWrapper,
  AutocompleteList,
  AutocompleteListInfo
} from './AutocompleteElements';
import debounce from 'lodash/debounce';
import Z_INDEX from '../constants/z-index';

const StyledChip = glamorous(Chip)({
  margin: '4px 2px'
});

const itemToString = item => (item ? item.name : '');

class LanguagesAutocomplete extends Component {
  state = this.getState({
    inputValue: '',
    debouncedValue: '',
    selectedItem: []
  });

  popperNode = React.createRef();

  // Gets the state based on internal state or props
  // If a state value is passed via props, then that
  // is the value given, otherwise it's retrieved from
  // stateToMerge
  getState(stateToMerge = this.state) {
    return Object.keys(stateToMerge).reduce((state, key) => {
      state[key] = this.isControlledProp(key)
        ? this.props[key]
        : stateToMerge[key];
      return state;
    }, {});
  }

  // This determines whether a prop is a "controlled prop" meaning it is
  // state which is controlled by the outside of this component rather
  // than within this component.
  isControlledProp(key) {
    return this.props[key] !== undefined;
  }

  // any piece of our state can live in two places:
  // 1. Uncontrolled: it's internal (this.state)
  //    We will call this.setState to update that state
  // 2. Controlled: it's external (this.props)
  //
  // In addition, we'll call this.props.onChange if the
  // selectedItem is changed.
  internalSetState = stateToSet => {
    const { onChange } = this.props;

    return this.setState(
      state => {
        return Object.keys(stateToSet).reduce((state, key) => {
          if (!this.isControlledProp(key)) {
            state[key] = stateToSet[key];
          }
          return state;
        }, {});
      },
      () => {
        if (stateToSet.hasOwnProperty('selectedItem')) {
          onChange && onChange(stateToSet.selectedItem);
        }
      }
    );
  };

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.getState();
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace'
    ) {
      this.internalSetState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1)
      });
    }
  };

  handleInputChange = event => {
    this.internalSetState({ inputValue: event.target.value });
  };

  handleInputBlur = () => {
    const { inputProps } = this.props;

    this.internalSetState({ inputValue: '' });
    inputProps && inputProps.blur && inputProps.blur();
  };

  handleChange = item => {
    let { selectedItem } = this.getState();

    if (!selectedItem.find(x => x === item)) {
      selectedItem = [
        ...selectedItem, item
      ];
    }

    this.internalSetState({ inputValue: '', debouncedValue: '', selectedItem });
  };

  handleDelete = item => () => {
    let { selectedItem } = this.getState();

    selectedItem = [...selectedItem];
    selectedItem.splice(selectedItem.indexOf(item), 1);

    this.internalSetState({
      selectedItem
    });
  };

  setDebounceValue = debounce(changes => {
    this.internalSetState({ debouncedValue: changes.inputValue });
  }, 500);

  handleStateChange = changes => {
    if (
      // need to check the type because on rerenders downshift will fire an action
      // that selectedItems changed because it does only shallow comparison
      changes.type === Downshift.stateChangeTypes.changeInput &&
      changes.hasOwnProperty('inputValue')
    ) {
      this.setDebounceValue(changes);
    }
  };

  render() {
    const { inputValue, selectedItem, debouncedValue } = this.getState();
    const {
      selectedItem: _,
      onChange,
      inputProps,
      groupId,
      excludeGroupId,
      label,
      placeholder,
      ...rest
    } = this.props;

    const filter = {
      name_contains: debouncedValue
    };

    if (groupId) {
      filter.groupId = groupId;
    }

    if (excludeGroupId) {
      filter.groupId_not_in = excludeGroupId;
    }

    return (
      <Downshift
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
        onStateChange={this.handleStateChange}
        itemToString={itemToString}
        {...rest}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <AutocompleteWrapper
              {...getRootProps({
                refKey: 'innerRef',
                fullWidth: inputProps && inputProps.fullWidth
              })}
            >
              <TextField
                {...getInputProps({
                  ...inputProps,
                  inputRef: this.popperNode,
                  label: label,
                  placeholder: placeholder,
                  InputProps: {
                    ...inputProps.InputProps,
                    startAdornment:
                      selectedItem &&
                      selectedItem.map(item => (
                        <StyledChip
                          key={item}
                          tabIndex={-1}
                          label={item}
                          onDelete={this.handleDelete(item)}
                        />
                      )),
                    classes: {
                      root: css({
                        flexWrap: 'wrap'
                      }).toString(),
                      input: css({
                        width: 'auto',
                        flexGrow: 1
                      }).toString()
                    }
                  },
                  onChange: this.handleInputChange,
                  onBlur: this.handleInputBlur,
                  onKeyDown: this.handleKeyDown
                })}
              />

              <Popper
                open={isOpen && !!debouncedValue}
                anchorEl={this.popperNode.current}
                className={`${css({ zIndex: Z_INDEX.autocomplete })}`}
              >
                <div
                  {...(isOpen
                    ? getMenuProps({}, { suppressRefError: true })
                    : {})}
                >
                  <AutocompleteList
                    width={
                      this.popperNode.current
                        ? this.popperNode.current.clientWidth
                        : null
                    }
                  >
                    <Query query={allLanguagesQuery} >
                      {({ loading, error, data: { allLanguages = [] } = {} }) => {
                        const langs = inputValue
                          ? matchSorter(allLanguages, inputValue)
                          : allLanguages;

                        if (loading) {
                          return (
                            <AutocompleteListInfo>
                              Loading...
                            </AutocompleteListInfo>
                          );
                        }

                        if (error) {
                          return (
                            <AutocompleteListInfo>
                              Error! ${error.message}
                            </AutocompleteListInfo>
                          );
                        }

                        if (langs.length === 0) {
                          return (
                            <AutocompleteListInfo>
                              No languages found.
                            </AutocompleteListInfo>
                          );
                        }

                        return langs.map((item, index) => (
                          <MenuItem
                            {...getItemProps({
                              key: item,
                              index,
                              item,
                              selected: highlightedIndex === index,
                              style: {
                                fontWeight:
                                  selectedItem && selectedItem === item
                                    ? 'bold'
                                    : 'normal'
                              }
                            })}
                          >
                            {item}
                          </MenuItem>
                        ));
                      }}
                    </Query>
                  </AutocompleteList>
                </div>
              </Popper>
            </AutocompleteWrapper>
          );
        }}
      </Downshift>
    );
  }
}

const allLanguagesQuery = gql`
  query allLanguages {
    allLanguages
  }
`;

export default LanguagesAutocomplete;
